import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useContent } from '../hooks/useContent';
import './PopupStyles.css';

const SurveyPopup = ({ onClose, userId, location, userLanguage }) => {
  const content = useContent('survey');
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleRating = async (value) => {
    setRating(value);
    
    const ratingData = {
      questions: [
        {
          question: content.questions.rating.text,
          answer: value,
          type: "rating"
        }
      ],
      metadata: {
        userId: userId,
        location: location,
        userLanguage: userLanguage,
        timestamp: new Date().toISOString()
      }
    };

    try {
      await fetch('https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/mt-feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(ratingData)
      });
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  const handleSubmit = async () => {
    if (feedback.trim()) {
      const feedbackData = {
        questions: [
          {
            question: content.questions.feedback.text,
            answer: feedback,
            type: "text"
          }
        ],
        metadata: {
          userId: userId,
          location: location,
          userLanguage: userLanguage,
          timestamp: new Date().toISOString()
        }
      };

      setSubmitted(true);

      fetch('https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/mt-feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(feedbackData)
      }).catch(error => {
        console.error('Error submitting feedback:', error);
      });
    }
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="popup-title">{content.title}</h2>
        
        {!submitted ? (
          <>
            <div className="survey-question">
              <p>{content.questions.rating.text}</p>
              <div className="rating-container">
                <div className="rating-labels">
                  <span className="rating-label">{content.questions.rating.labels.min}</span>
                  <span className="rating-label middle">{content.questions.rating.labels.middle}</span>
                  <span className="rating-label">{content.questions.rating.labels.max}</span>
                </div>
                <div className="rating-buttons">
                  {[...Array(11)].map((_, index) => (
                    <button
                      key={index}
                      className={`rating-button ${rating === index ? 'selected' : ''}`}
                      onClick={() => handleRating(index)}
                    >
                      {index}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div className="survey-question">
              <p>{content.questions.feedback.text}</p>
              <textarea
                className="feedback-textarea"
                placeholder={content.questions.feedback.placeholder}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </div>

            <button 
              className="popup-button primary submit-button" 
              onClick={handleSubmit}
              disabled={feedback.trim() === ''}
            >
              {content.buttons.submit}
            </button>
          </>
        ) : (
          <p className="thank-you-message">{content.thankYou}</p>
        )}
      </div>
    </div>
  );
};

export default SurveyPopup;