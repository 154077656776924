import React, { useState, useRef, useEffect, forwardRef, useCallback } from 'react';
import * as tf from '@tensorflow/tfjs'; //USE IF RUNNING MOBILENET
import { CATEGORIES, TITLES, RLM_Cabinet_CATEGORIES, RLM_orangepot_CATEGORIES, RLM_blackpot_CATEGORIES, RLM_figure_CATEGORIES, RLM_signage_CATEGORIES } from '../config'; 
// import axios from 'axios';  //USE IF RUNNING CV LOCALLY
import { thresholdConfig, getThreshold, groupConfig, groupThresholds } from '../thresholds';
import { useContent } from '../hooks/useContent';

const VideoCapture = forwardRef(({ handleResponseTitleChange, onFirstInferenceComplete, onLoadingProgress, userLanguage, userId }, ref) => {
    const content = useContent('camera');
    
    // const ipAddress = process.env.REACT_APP_IP_ADDRESS; //LOCAL VERSION
    // const ipAddressAWSIR = process.env.REACT_APP_AWS_IR_API_ENDPOINT;  //AWS VERSION
    const [model, setModel] = useState(null); //MOBILENET VERSION
    //above is 20240924 version
    // Version without RS Cabinet = ['1.0', '10.0', '11.0', '12.0', '13.0', '14.0', '15.0', '16.0', '17.0', '18.0', '19.0', '2.0', '20.0', '21.0', '22.0', '23.0', '24.0', '25.0', '26.0', '27.0', '28.0', '29.0', '3.0', '30.0', '31.0', '32.0', '33.0', '34.0', '35.0', '36.0', '37.0', '38.0', '39.0', '4.0', '40.0', '41.0', '42.0', '43.0', '43.1', '43.2', '43.3', '43.4', '43.5', '43.6', '43.7', '43.8', '44.0', '45.0', '46.0', '47.0', '48.0', '49.0', '5.0', '50.0', '51.0', '52.0', '53.0', '54.0', '55.0', '56.0', '57.0', '58.0', '59.0', '6.0', '60.0', '61.0', '62.0', '63.1', '63.2', '63.3', '63.4', '63.5', '63.6', '63.7', '64.0', '65.0', '66.0', '67.0', '68.0', '69.0', '7.0', '70.0', '71.0', '72.0', '73.0', '74.0', '75.0', '76.1', '76.2', '76.3', '8.0', '9.0', '_other'];
    const videoRef = useRef(null);
    const [isCameraReady, setIsCameraReady] = useState(false);
    const [zoomLevel, setZoomLevel] = useState('Unknown');
    const [additionalZoom, setAdditionalZoom] = useState(1);
    const [scaleFactor, setScaleFactor] = useState(1);
    // const [processedImageSrc, setProcessedImageSrc] = useState(''); // FOR TESTING IMAGE

    // const [lastDetectedObject, setLastDetectedObject] = useState(null);
    const lastDetectedObjectRef = useRef(null);
    const dummyCallMadeRef = useRef(false); // useRef instead of useState
    const [cameraError, setCameraError] = useState(null);
    const [modelLoadingStatus, setModelLoadingStatus] = useState('Not started');
    const modelLoadStartTime = useRef(null);
    const [initializationTimeoutReached, setInitializationTimeoutReached] = useState(false);
    const [isInferenceRunning, setIsInferenceRunning] = useState(true);
    const lastInferenceTimeRef = useRef(Date.now());
    const [isWaitingForPermission, setIsWaitingForPermission] = useState(false);
    const [cameraPermissionDenied, setCameraPermissionDenied] = useState(false); // New state for permission denial
    const [isPaused, setIsPaused] = useState(false);
    const firstInferenceCompleted = useRef(false);
    const appStartTime = useRef(Date.now());

    const [cabinetModel, setCabinetModel] = useState(null);
    const [cabinetModelLoadingStatus, setCabinetModelLoadingStatus] = useState('Not started');
    const cabinetModelRef = useRef(null);

    const [figureModel, setFigureModel] = useState(null);
    const [figureModelLoadingStatus, setFigureModelLoadingStatus] = useState('Not started');
    const figureModelRef = useRef(null);

    const [signageModel, setSignageModel] = useState(null);
    const [signageModelLoadingStatus, setSignageModelLoadingStatus] = useState('Not started');
    const signageModelRef = useRef(null);

    const [orangepotModel, setorangepotModel] = useState(null);
    const [orangepotModelLoadingStatus, setorangepotModelLoadingStatus] = useState('Not started');
    const orangepotModelRef = useRef(null);

    const [blackpotModel, setBlackpotModel] = useState(null);
    const [blackpotModelLoadingStatus, setBlackpotModelLoadingStatus] = useState('Not started');
    const blackpotModelRef = useRef(null);

    const [isCameraPermissionGranted, setIsCameraPermissionGranted] = useState(false);
    const [showFinalErrorMessage, setShowFinalErrorMessage] = useState(false);

    //MOBILENET
    useEffect(() => {
        const loadModel = async () => {
            const isFirstEverLoad = !localStorage.getItem('hasLoadedBefore');
            if (isFirstEverLoad) {
                localStorage.setItem('hasLoadedBefore', 'true');
                onLoadingProgress(10);
            }
    
            const startLoadTime = Date.now();
            console.log(`[Timing] App start -> Model load start: ${startLoadTime - appStartTime.current}ms`);
            console.log("[Timing] Starting model load...");
            setModelLoadingStatus('Loading');
            modelLoadStartTime.current = startLoadTime;
            console.log("Model URL:", process.env.REACT_APP_MODEL_URL);
            
            try {
                if (isFirstEverLoad) {
                    onLoadingProgress(20);
                }
    
                const model = await tf.loadLayersModel(process.env.REACT_APP_MODEL_URL);
                
                if (isFirstEverLoad) {
                    onLoadingProgress(40);
                }
    
                const loadCompleteTime = Date.now();
                const loadDuration = loadCompleteTime - startLoadTime;
                console.log(`[Timing] Model load duration: ${loadDuration}ms`);
                console.log(`[Timing] App start -> Model load complete: ${loadCompleteTime - appStartTime.current}ms`);
                
                console.log("[Timing] Starting warmup inference...");
                const warmupStartTime = Date.now();
                const dummyTensor = tf.zeros([1, 224, 224, 3]);
                const predictionPromise = model.predict(dummyTensor);
    
                if (isFirstEverLoad) {
                    let currentProgress = 40;
                    while (currentProgress < 90) {
                        currentProgress += 10;
                        onLoadingProgress(currentProgress);
                        await new Promise(resolve => setTimeout(resolve, 750));
                    }
                }
    
                await predictionPromise.data();
                dummyTensor.dispose();
    
                const warmupDuration = Date.now() - warmupStartTime;
                console.log(`[Timing] Warmup inference completed in ${warmupDuration}ms`);
    
                setModelLoadingStatus('Loaded');
                setModel(model);
                
                if (isFirstEverLoad) {
                    onLoadingProgress(100);
                }
    
            } catch (error) {
                console.error("Error loading model or running warmup:", error);
                setModelLoadingStatus('Error');
            }
        };
        
        loadModel();
    }, []); // Empty dependency array

    // REMOVING THE BELOW WHICH SEEMED TO LOAD ALL THE MODELS AT THE SAME TIME
    // // Cabinet model loading (first specialized model)
    // useEffect(() => {
    //     const loadCabinetModel = async () => {
    //         if (firstInferenceCompleted.current && !cabinetModel) {
    //             console.log("[Timing] Starting cabinet model load...");
    //             setCabinetModelLoadingStatus('Loading');
    //             try {
    //                 // tf.engine().startScope();
    //                 const model = await tf.loadLayersModel('https://rlm-mobilenetv2-cabinets-live.s3.eu-west-2.amazonaws.com/model.json');
                    
    //                 // Run dummy inference for warm-up
    //                 // const dummyTensor = tf.zeros([1, 224, 224, 3]);
    //                 // await model.predict(dummyTensor).data();
    //                 // dummyTensor.dispose();
    //                 // console.log("Dummy inference completed for Cabinet model warm-up");
                    
    //                 setCabinetModelLoadingStatus('Loaded');
    //                 cabinetModelRef.current = model;
    //             } catch (error) {
    //                 console.error("Error loading cabinet model:", error);
    //                 setCabinetModelLoadingStatus('Error');
    //             } finally {
    //                 // tf.engine().endScope();
    //             }
    //         }
    //     };
    //     loadCabinetModel();
    // }, [firstInferenceCompleted.current]);

    // // Figure model loading (second specialized model)
    // useEffect(() => {
    //     const loadFigureModel = async () => {
    //         if (firstInferenceCompleted.current && !figureModel && cabinetModelLoadingStatus === 'Loaded') {
    //             console.log("[Timing] Starting figure model load...");
    //             setFigureModelLoadingStatus('Loading');
    //             try {
    //                 // tf.engine().startScope();
    //                 const model = await tf.loadLayersModel('https://rlm-mobilenetv2-figures-live.s3.eu-west-2.amazonaws.com/model.json');
                    
    //                 // Run dummy inference for warm-up
    //                 // const dummyTensor = tf.zeros([1, 224, 224, 3]);
    //                 // await model.predict(dummyTensor).data();
    //                 // dummyTensor.dispose();
    //                 // console.log("Dummy inference completed for Figure model warm-up");
                    
    //                 setFigureModelLoadingStatus('Loaded');
    //                 figureModelRef.current = model;
    //             } catch (error) {
    //                 console.error("Error loading figure model:", error);
    //                 setFigureModelLoadingStatus('Error');
    //             } finally {
    //                 // tf.engine().endScope();
    //             }
    //         }
    //     };
    //     loadFigureModel();
    // }, [firstInferenceCompleted.current, cabinetModelLoadingStatus]);

    // // Signage model loading (third specialized model)
    // useEffect(() => {
    //     const loadSignageModel = async () => {
    //         if (firstInferenceCompleted.current && !signageModel && figureModelLoadingStatus === 'Loaded') {
    //             console.log("[Timing] Starting signage model load...");
    //             setSignageModelLoadingStatus('Loading');
    //             try {
    //                 // tf.engine().startScope();
    //                 const model = await tf.loadLayersModel('https://rlm-mobilenetv2-signage-live.s3.eu-west-2.amazonaws.com/model.json');
                    
    //                 // Run dummy inference for warm-up
    //                 // const dummyTensor = tf.zeros([1, 224, 224, 3]);
    //                 // await model.predict(dummyTensor).data();
    //                 // dummyTensor.dispose();
    //                 // console.log("Dummy inference completed for Signage model warm-up");
                    
    //                 setSignageModelLoadingStatus('Loaded');
    //                 signageModelRef.current = model;
    //             } catch (error) {
    //                 console.error("Error loading signage model:", error);
    //                 setSignageModelLoadingStatus('Error');
    //             } finally {
    //                 // tf.engine().endScope();
    //             }
    //         }
    //     };
    //     loadSignageModel();
    // }, [firstInferenceCompleted.current, figureModelLoadingStatus]);

    // // Orange pots model loading (fourth specialized model)
    // useEffect(() => {
    //     const loadOrangepotModel = async () => {
    //         if (firstInferenceCompleted.current && !orangepotModel && signageModelLoadingStatus === 'Loaded') {
    //             console.log("[Timing] Starting orangepot model load...");
    //             setorangepotModelLoadingStatus('Loading');
    //             try {
    //                 // tf.engine().startScope();
    //                 const model = await tf.loadLayersModel('https://rlm-mobilenetv2-orangepots-live.s3.eu-west-2.amazonaws.com/model.json');
                    
    //                 // Run dummy inference for warm-up
    //                 // const dummyTensor = tf.zeros([1, 224, 224, 3]);
    //                 // await model.predict(dummyTensor).data();
    //                 // dummyTensor.dispose();
    //                 // console.log("Dummy inference completed for Orangepot model warm-up");
                    
    //                 setorangepotModelLoadingStatus('Loaded');
    //                 orangepotModelRef.current = model;
    //             } catch (error) {
    //                 console.error("Error loading orangepot model:", error);
    //                 setorangepotModelLoadingStatus('Error');
    //             } finally {
    //                 // tf.engine().endScope();
    //             }
    //         }
    //     };
    //     loadOrangepotModel();
    // }, [firstInferenceCompleted.current, signageModelLoadingStatus]);

    // // Black pots model loading (fifth specialized model)
    // useEffect(() => {
    //     const loadBlackpotModel = async () => {
    //         if (firstInferenceCompleted.current && !blackpotModel && orangepotModelLoadingStatus === 'Loaded') {
    //             console.log("[Timing] Starting blackpot model load...");
    //             setBlackpotModelLoadingStatus('Loading');
    //             try {
    //                 // tf.engine().startScope();
    //                 const model = await tf.loadLayersModel('https://rlm-mobilenetv2-blackpots-live.s3.eu-west-2.amazonaws.com/model.json');
                    
    //                 // Run dummy inference for warm-up
    //                 // const dummyTensor = tf.zeros([1, 224, 224, 3]);
    //                 // await model.predict(dummyTensor).data();
    //                 // dummyTensor.dispose();
    //                 // console.log("Dummy inference completed for Blackpot model warm-up");
                    
    //                 setBlackpotModelLoadingStatus('Loaded');
    //                 blackpotModelRef.current = model;
    //             } catch (error) {
    //                 console.error("Error loading blackpot model:", error);
    //                 setBlackpotModelLoadingStatus('Error');
    //             } finally {
    //                 // tf.engine().endScope();
    //             }
    //         }
    //     };
    //     loadBlackpotModel();
    // }, [firstInferenceCompleted.current, orangepotModelLoadingStatus]);

    // Sequential model loading
    useEffect(() => {
        const loadModelsSequentially = async () => {
            if (firstInferenceCompleted.current) {
                // Cabinet Model
                try {
                    console.log("[Timing] Starting cabinet model load...");
                    setCabinetModelLoadingStatus('Loading');
                    const cabinetModel = await tf.loadLayersModel('https://rlm-mobilenetv2-cabinets-live.s3.eu-west-2.amazonaws.com/model.json');
                    setCabinetModelLoadingStatus('Loaded');
                    cabinetModelRef.current = cabinetModel;
                } catch (error) {
                    console.error("Error loading cabinet model:", error);
                    setCabinetModelLoadingStatus('Error');
                }
                
                // Wait before loading next model
                await new Promise(resolve => setTimeout(resolve, 1000));
    
                // Figure Model
                try {
                    console.log("[Timing] Starting figure model load...");
                    setFigureModelLoadingStatus('Loading');
                    const figureModel = await tf.loadLayersModel('https://rlm-mobilenetv2-figures-live.s3.eu-west-2.amazonaws.com/model.json');
                    setFigureModelLoadingStatus('Loaded');
                    figureModelRef.current = figureModel;
                } catch (error) {
                    console.error("Error loading figure model:", error);
                    setFigureModelLoadingStatus('Error');
                }
    
                await new Promise(resolve => setTimeout(resolve, 1000));
    
                // Signage Model
                try {
                    console.log("[Timing] Starting signage model load...");
                    setSignageModelLoadingStatus('Loading');
                    const signageModel = await tf.loadLayersModel('https://rlm-mobilenetv2-signage-live.s3.eu-west-2.amazonaws.com/model.json');
                    setSignageModelLoadingStatus('Loaded');
                    signageModelRef.current = signageModel;
                } catch (error) {
                    console.error("Error loading signage model:", error);
                    setSignageModelLoadingStatus('Error');
                }
    
                await new Promise(resolve => setTimeout(resolve, 1000));
    
                // Orangepot Model
                try {
                    console.log("[Timing] Starting orangepot model load...");
                    setorangepotModelLoadingStatus('Loading');
                    const orangepotModel = await tf.loadLayersModel('https://rlm-mobilenetv2-orangepots-live.s3.eu-west-2.amazonaws.com/model.json');
                    setorangepotModelLoadingStatus('Loaded');
                    orangepotModelRef.current = orangepotModel;
                } catch (error) {
                    console.error("Error loading orangepot model:", error);
                    setorangepotModelLoadingStatus('Error');
                }
    
                await new Promise(resolve => setTimeout(resolve, 1000));
    
                // Blackpot Model
                try {
                    console.log("[Timing] Starting blackpot model load...");
                    setBlackpotModelLoadingStatus('Loading');
                    const blackpotModel = await tf.loadLayersModel('https://rlm-mobilenetv2-blackpots-live.s3.eu-west-2.amazonaws.com/model.json');
                    setBlackpotModelLoadingStatus('Loaded');
                    blackpotModelRef.current = blackpotModel;
                } catch (error) {
                    console.error("Error loading blackpot model:", error);
                    setBlackpotModelLoadingStatus('Error');
                }
            }
        };
    
        loadModelsSequentially();
    }, [firstInferenceCompleted.current]);


    
    // Function to start the camera
    const startCamera = useCallback(async () => {
        console.log("Attempting to start camera...");
        setInitializationTimeoutReached(false);
        setIsWaitingForPermission(true);

        try {
            // If we have a paused stream, just resume it
            if (isPaused && videoRef.current && videoRef.current.srcObject) {
                videoRef.current.play();
                setIsPaused(false);
                setIsCameraReady(true);
                return;
            }

            // Otherwise, request a new stream
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: 'environment',
                    width: { ideal: 2560 },
                    height: { ideal: 1920 },
                    zoom: { ideal: 0.5 },
                }
            });
            console.log("Camera stream obtained successfully");
            setIsWaitingForPermission(false);

            // Listen for the stream ending (e.g., permission revoked)
            stream.getVideoTracks()[0].addEventListener('ended', () => {
                console.log("Camera stream ended, permission may have been revoked.");
                setCameraPermissionDenied(true); // Update state to reflect permission denial
                stopCamera(); // Stop the camera
            });

            // Start the initialization timeout only after permission is granted
            const initializationTimeout = setTimeout(() => {
                setInitializationTimeoutReached(true);
            }, 5000);

            videoRef.current.srcObject = stream;
            await initCameraSettings(stream);
            setTimeout(() => {
                setIsCameraReady(true);
                console.log("Camera ready");
                clearTimeout(initializationTimeout); // Clear the timeout if camera becomes ready
            }, 850); //CHANGED FROM 1500 TO 800, to 200 TO TEST IF IT CAN BE FASTER - THIS IS NOT INFERENCE TIME!!!!
        } catch (error) {
            console.error("Error accessing camera:", error);
            setCameraError(error.message);
            setIsWaitingForPermission(false);
        }
    }, [isPaused]);

    // Function to initialize camera settings
    async function initCameraSettings(stream) {
        console.log("Initializing camera settings...");
        const videoTrack = stream.getVideoTracks()[0];
        const capabilities = videoTrack.getCapabilities && videoTrack.getCapabilities();
        if (capabilities && capabilities.zoom) {
            const minZoom = capabilities.zoom.min;
            await videoTrack.applyConstraints({ advanced: [{ zoom: minZoom }] });
            console.log(`Applied minimum zoom: ${minZoom}`);
        }
        const settings = videoTrack.getSettings();
        setZoomLevel(settings.zoom || 'Not Supported');
        setScaleFactor(settings.zoom === 0.5 ? 1.8 : 1);
        console.log("Camera settings initialized:", settings);
    }

    // Function to stop the camera
    function stopCamera(temporary = false) {
        console.log(`[Camera] Stopping camera (${temporary ? 'temporary' : 'full'} stop)`);
        if (videoRef.current && videoRef.current.srcObject) {
            if (temporary) {
                console.log('[Camera] Pausing video element');
                videoRef.current.pause();
                
                // Log active tracks
                const tracks = videoRef.current.srcObject.getTracks(); //BELIEVE THIS LINE IS ONLY FOR LOGGING PURPOSES
                console.log('[Camera] Active tracks:', tracks.map(t => ({
                    kind: t.kind,
                    enabled: t.enabled,
                    readyState: t.readyState
                })));
                
                setIsPaused(true);
            } else {
                // Full stop - only for component unmount
                console.log('[Camera] Full stop - releasing all tracks');
                const tracks = videoRef.current.srcObject.getTracks();
                tracks.forEach(track => {
                    console.log(`[Camera] Stopping track: ${track.kind}`);
                    track.stop();
                });
                videoRef.current.srcObject = null;
            }
        }
    }
    // Clean up on unmount only
    useEffect(() => {
        return () => stopCamera(false); // full stop on unmount
    }, []);

    // Expose methods to parent
    useEffect(() => {
        ref.current = {
            startCamera,
            stopCamera: () => stopCamera(true), // temporary stop by default
            getVideoElement: () => videoRef.current,
        };
    }, [ref, startCamera]);

    // Function to capture and send image
    async function captureAndProcessImage() {
        const inferenceStartTime = Date.now();
        
        if (isPaused) {
            console.log('[Inference] Skipping inference - camera is paused');
            return;
        }
    
        if (isCameraReady && videoRef.current && videoRef.current.readyState === 4 && model) {
            setIsInferenceRunning(true);
            lastInferenceTimeRef.current = inferenceStartTime;
            
            // Create canvas outside of tf.tidy since we need it for multiple operations
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const { videoWidth, videoHeight } = videoRef.current;
            const zoomInFactor = (zoomLevel === 0.5 ? 1.8 : 1) * additionalZoom;
            const size = Math.min(videoWidth, videoHeight) / zoomInFactor;
            const startX = (videoWidth - size) / 2;
            const offsetPercentage = -1;
            const offsetY = (offsetPercentage / 100) * videoHeight;
            const startY = ((videoHeight - size) / 2) + offsetY;
            canvas.width = size;
            canvas.height = size;
            context.drawImage(videoRef.current, startX, startY, size, size, 0, 0, size, size);
    
            try {
                // const beforeTensors = tf.memory().numTensors;  // Add tracking
                
                tf.engine().startScope();
                
                // Create the base tensor that will be used by all models
                const baseTensor = tf.tidy(() => {
                    return tf.browser.fromPixels(canvas)
                        .resizeNearestNeighbor([224, 224])
                        .toFloat()
                        .div(255.0)
                        .expandDims();
                });
    
                console.log("-----------------------------");
    
                // Initial model prediction
                const predictions = await tf.tidy(() => model.predict(baseTensor)).data();
    
                // After successful inference, check if it's the first one
                if (!firstInferenceCompleted.current) {
                    firstInferenceCompleted.current = true;
                    onFirstInferenceComplete();
                }
    
                // Create predictions array and process groups
                const predictionsArray = Array.from(predictions).map((probability, index) => ({
                    id: CATEGORIES[index],
                    title: TITLES[index],
                    likelihood: (probability * 100).toFixed(2)
                }));
    
                const cabinetIds = ['55.0', '56.0', '57.0', '58.0', '60.0', '61.0', 
                    '65.0', '66.0', '67.0', '68.0', '69.0', '70.0', 
                    '71.0', '72.0', '73.0'];

                const figureIds = ['1', '50', '59'];
    
                const signageIds = ['7.0', '8.0', '9.0', '10.0', '11', '12', '13', '16.0', '2', '21.0', '22.2', '29', '3.0', '30.1', '4.0', '42', '43.1', '5.0', '51.1'];
                
                const orangepotIds = ['56.1', '56.14', '56.19', '56.2', '56.25', '56.27', '56.4', '56.8', '56.9', '65.1', '65.10', '65.11', '65.12', '65.19', '65.2', '65.20', '65.21', '65.22', '65.3', '65.6', '65.7', '65.8', '65.9', '66.1', '66.14', '66.2', '66.22', '66.24', '66.4', '66.5', '68.4.1.2', '70.1', '70.10', '70.11', '70.12', '70.13', '70.14.0', '70.14.1', '70.14.2', '70.15', '70.16', '70.17', '70.18', '70.2', '70.22.1', '70.22.2', '70.23', '70.3', '70.4', '70.5', '70.6', '70.7', '70.8', '70.9', '71.4', '71.9.1', '72.1', '72.9.1'];
    
                const blackpotIds = ['56.10', '56.11', '56.12', '56.13', '56.15', '56.16', '56.17', '56.20', '56.22', '56.24', '56.26', '56.5', '56.7', '65.14', '65.27', '66.10', '66.11', '66.12', '66.13', '66.15', '66.16.0', '66.17', '66.18', '66.19', '66.20', '66.21', '66.23', '66.3', '66.6', '66.7', '66.9', '69.2', '70.20', '71.5', '71.6', '72.9.2'];
                
                const groupedPredictions = predictionsArray.reduce((acc, pred) => {
                    if (cabinetIds.includes(pred.id)) {
                        const cabinetEntry = acc.find(item => item.id === 'Cabinet');
                        if (cabinetEntry) {
                            cabinetEntry.likelihood = (parseFloat(cabinetEntry.likelihood) + parseFloat(pred.likelihood)).toFixed(2);
                        } else {
                            acc.push({
                                id: 'Cabinet',
                                title: 'Cabinet',
                                likelihood: pred.likelihood
                            });
                        }
                    } else if (figureIds.includes(pred.id)) {
                        const figureEntry = acc.find(item => item.id === 'Figure');
                        if (figureEntry) {
                            figureEntry.likelihood = (parseFloat(figureEntry.likelihood) + parseFloat(pred.likelihood)).toFixed(2);
                        } else {
                            acc.push({
                                id: 'Figure',
                                title: 'Figure',
                                likelihood: pred.likelihood
                            });
                        }
                    } else if (signageIds.includes(pred.id)) {
                        const signageEntry = acc.find(item => item.id === 'Signage');
                        if (signageEntry) {
                            signageEntry.likelihood = (parseFloat(signageEntry.likelihood) + parseFloat(pred.likelihood)).toFixed(2);
                        } else {
                            acc.push({
                                id: 'Signage',
                                title: 'Signage',
                                likelihood: pred.likelihood
                            });
                        }
                    } else if (orangepotIds.includes(pred.id)) {
                        const orangepotEntry = acc.find(item => item.id === 'Orangepot');
                        if (orangepotEntry) {
                            orangepotEntry.likelihood = (parseFloat(orangepotEntry.likelihood) + parseFloat(pred.likelihood)).toFixed(2);
                        } else {
                            acc.push({
                                id: 'Orangepot',
                                title: 'Orangepot',
                                likelihood: pred.likelihood
                            });
                        }
                    } else if (blackpotIds.includes(pred.id)) {
                        const blackpotEntry = acc.find(item => item.id === 'Blackpot');
                        if (blackpotEntry) {
                            blackpotEntry.likelihood = (parseFloat(blackpotEntry.likelihood) + parseFloat(pred.likelihood)).toFixed(2);
                        } else {
                            acc.push({
                                id: 'Blackpot',
                                title: 'Blackpot',
                                likelihood: pred.likelihood
                            });
                        }
                    } else {
                        acc.push(pred);
                    }
                    return acc;
                }, []);
    
                const sortedPredictions = groupedPredictions.sort((a, b) => b.likelihood - a.likelihood);
                const topPredictions = sortedPredictions.slice(0, 5);
    
                console.log("Top 5 predictions:");
                topPredictions.forEach(prediction => {
                    console.log(`Title: ${prediction.title}, ID: ${prediction.id}, Likelihood: ${prediction.likelihood}%`);
                });
    
                let topPredictionCategory = topPredictions[0].id;
                const topPredictionProbability = parseFloat(topPredictions[0].likelihood) / 100;
    
                const threshold = getThreshold(topPredictionCategory);
    
                console.log("Initial prediction:", {
                    category: topPredictionCategory,
                    probability: topPredictionProbability,
                    threshold: threshold
                });
    
                if (topPredictionProbability < threshold) {
                    console.log(`Prediction below ${threshold * 100}% threshold for category ${topPredictionCategory}, classifying as '_other'`);
                    topPredictionCategory = '_other';
                }

                // If it's Cabinet or Orangepot and above threshold, use respective model
                else if (topPredictionCategory === 'Cabinet' || topPredictionCategory === 'Figure' || 
                        topPredictionCategory === 'Signage' || topPredictionCategory === 'Orangepot' || 
                        topPredictionCategory === 'Blackpot') {
                    
                    let modelRef, modelType, categories;
                    
                    switch(topPredictionCategory) {
                        case 'Cabinet':
                            modelRef = cabinetModelRef;
                            modelType = 'Cabinet';
                            categories = RLM_Cabinet_CATEGORIES;
                            break;
                        case 'Figure':
                            modelRef = figureModelRef;
                            modelType = 'Figure';
                            categories = RLM_figure_CATEGORIES;
                            break;
                        case 'Signage':
                            modelRef = signageModelRef;
                            modelType = 'Signage';
                            categories = RLM_signage_CATEGORIES;
                            break;
                        case 'Orangepot':
                            modelRef = orangepotModelRef;
                            modelType = 'Orangepot';
                            categories = RLM_orangepot_CATEGORIES;
                            break;
                        case 'Blackpot':
                            modelRef = blackpotModelRef;
                            modelType = 'Blackpot';
                            categories = RLM_blackpot_CATEGORIES;
                            break;
                    }
    
                    console.log(`${modelType} detected, checking model availability:`, {
                        isType: topPredictionCategory === modelType,
                        modelExists: !!modelRef.current,
                        modelType: typeof modelRef.current,
                        modelValue: modelRef.current
                    });
                    
                    if (modelRef.current) {
                        console.log(`${modelType} model available, running predictions...`);
                        try {
                            const specializedPredictions = await tf.tidy(() => modelRef.current.predict(baseTensor)).data();
    
                            console.log(`\n${modelType} Model Predictions:`);
                            console.log('|||||||||||||||||||||||||');
                            categories.forEach((category, index) => {
                                const likelihood = (specializedPredictions[index] * 100).toFixed(2);
                                console.log(`${category}: ${likelihood}%`);
                            });
                            console.log('|||||||||||||||||||||||||n');
    
                            const maxProbability = Math.max(...specializedPredictions);
                            const FOCUSED_MODEL_THRESHOLD = 0.60;
    
                            if (maxProbability >= FOCUSED_MODEL_THRESHOLD) {
                                if (modelType === 'Signage') {
                                    // Strip trailing letter from signage prediction (e.g., '5.0A' -> '5.0')
                                    topPredictionCategory = categories[specializedPredictions.indexOf(maxProbability)].replace(/[a-zA-Z]$/, '');
                                } else {
                                    topPredictionCategory = categories[specializedPredictions.indexOf(maxProbability)];
                                }
                                console.log(`Selected prediction: ${topPredictionCategory} (${(maxProbability * 100).toFixed(2)}%)`);
                            } else {
                                topPredictionCategory = '_other';
                                console.log(`Prediction reclassified as '_other' due to insufficient likelihood (${(maxProbability * 100).toFixed(2)}% < ${FOCUSED_MODEL_THRESHOLD * 100}%)`);
                            }
                        } catch (error) {
                            console.error(`Error running ${modelType.toLowerCase()} model:`, error);
                            topPredictionCategory = '_other';
                        }
                    }
                }
    
                // Handle dummy calls and backend communication
                if (topPredictionCategory === '_other') {
                    if (!dummyCallMadeRef.current) {
                        console.log("Making dummy calls to backend for cold start prevention.");
                        dummyCallMadeRef.current = true;
    
                        try {
                            // Fire and forget - don't await responses
                            fetch(process.env.REACT_APP_SUMMARY_DATA_ENDPOINT, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ Object_ID: topPredictionCategory })
                            }).catch(() => {}); // Silent error handling

                            fetch(process.env.REACT_APP_TEXT_GENERATION_ENDPOINT, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ dummy: true })
                            }).catch(() => {});

                            fetch('https://kjgw2i5dhh.execute-api.eu-west-2.amazonaws.com/TextToSpeechFunction_Prototype', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ dummy: true })
                            }).catch(() => {});

                            fetch('https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/validation', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ dummy: true })
                            }).catch(() => {});

                            fetch('https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/speech-to-text', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ dummy: true })
                            }).catch(() => {});

                            console.log("Dummy calls initiated");
    
                        } catch (error) {
                            // Even the try-catch is probably unnecessary here, but keeping it just in case
                            console.log("Error initiating dummy calls (non-critical)");
                        }
                    }
                } else if (topPredictionCategory !== lastDetectedObjectRef.current) {
                    lastDetectedObjectRef.current = topPredictionCategory;
                    
                    try {
                        const response = await fetch(process.env.REACT_APP_SUMMARY_DATA_ENDPOINT, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ 
                                Object_ID: topPredictionCategory,
                                user_language: userLanguage,
                                userId: userId,
                                location: process.env.REACT_APP_LOCATION
                            })
                        });
    
                        const data = await response.json();
                        handleResponseTitleChange(
                            data.object_title,
                            data.prompt_data,
                            data.summary_data,
                            data.thumbnail_image,
                            data.additional_instructions,
                            data.object_subtitle,
                            topPredictionCategory
                        );
                    } catch (error) {
                        console.error("Error sending object ID to backend:", error);
                    }
                }
    
                // Cleanup
                tf.dispose(baseTensor);
                
                // Log if we detect tensor leakage - THERE ACTUALLY DOESNT SEEM TO BE ANY LEAKAGE, IT CLEANS UP QUCIKLY
                // const afterTensors = tf.memory().numTensors;
                // if (afterTensors > beforeTensors) {
                //     console.warn('Tensor leak detected:', {
                //         before: beforeTensors,
                //         after: afterTensors,
                //         difference: afterTensors - beforeTensors,
                //         stack: new Error().stack
                //     });
                // }

            } catch (error) {
                console.error("Error during prediction:", error);
            } finally {
                tf.engine().endScope();
            }
        } else {
            console.log("[Timing] Skipping image capture - conditions not met:", {
                isCameraReady,
                videoReady: videoRef.current?.readyState === 4,
                modelLoaded: !!model,
                timeFromStart: Date.now() - appStartTime.current
            });
        }
    }

    useEffect(() => {
        const memoryCheckInterval = setInterval(() => {
            const memoryInfo = tf.memory();
            console.log('Memory status:', {
                timestamp: new Date().toISOString(),
                numTensors: memoryInfo.numTensors,
                numDataBuffers: memoryInfo.numDataBuffers,
                numBytes: (memoryInfo.numBytes / 1024 / 1024).toFixed(2) + ' MB',
                unreliable: memoryInfo.unreliable,
                reasons: memoryInfo.unreliable ? memoryInfo.reasons : undefined
            });
        }, 1000);
    
        return () => clearInterval(memoryCheckInterval);
    }, []);

    // Keep only this useEffect for inference
    useEffect(() => {
        let captureInterval;
        let isProcessing = false; // Move outside setInterval to ensure it persists between intervals
        
        if (isCameraReady && !isPaused && model) {
            const effectTime = Date.now();
            console.log(`[Timing] Inference useEffect triggered: ${effectTime - appStartTime.current}ms`);
            console.log('[Timing] Starting capture interval - Camera ready:', isCameraReady, 'Model loaded:', !!model, 'Paused:', isPaused);

            // Run first inference immediately with protection
            (async () => {
                if (!isProcessing) {
                    isProcessing = true;
                    try {
                        await captureAndProcessImage();
                    } finally {
                        isProcessing = false;
                    }
                }
            })();
            
            // Add protection against concurrent executions
            captureInterval = setInterval(async () => {  //NOT SURE THIS ISPROCESSING ACTUALLY HELPS, BUT MAKES SENSE THAT IT SHOULD HELP STOP A BACKLOG BUILDING, CHECK LATER IF IT IS ACTUALLY CAUSING IT TO GET SLOWER
                if (isProcessing) {
                    console.log('[Timing] Skipping inference - previous one still running');
                    return;
                }
                isProcessing = true;
                try {
                    await captureAndProcessImage();
                } catch (error) {
                    console.error('Error in inference cycle:', error);
                    // Maybe add some error handling here
                } finally {
                    isProcessing = false;
                }
            }, 1000); //INTERVAL SET HERE FOR INFERENCE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        } else {
            console.log('[Timing] Not starting interval - Camera ready:', isCameraReady, 'Model loaded:', !!model, 'Paused:', isPaused);
        }
        return () => {
            if (captureInterval) {
                // console.log('[Inference] Cleaning up interval');
                clearInterval(captureInterval);
                isProcessing = false; // Reset processing flag on cleanup
            }
        };
    }, [isCameraReady, isPaused, zoomLevel, additionalZoom, model, userLanguage]);

    //UNCOMMENT BELOW TO NOT USE COOKIES AND LOCAL STORAGE//
    // Start the camera when the component mounts
    // useEffect(() => {
    //     startCamera();
    //     return () => stopCamera();
    // }, []);
    //UNCOMMENT ABOVE TO NOT USE COOKIES AND LOCAL STORAGE//

    //UNCOMMENT BELOW TO USE COOKIES AND LOCAL STORAGE//

    function setCookie(name, value, days) {
        const expires = new Date(Date.now() + days * 864e5).toUTCString();
        document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=/';
    }
    
    function getCookie(name) {
        return document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=');
            return parts[0] === name ? decodeURIComponent(parts[1]) : r;
        }, '');
    }
    

    useEffect(() => {
        const checkPermissions = async () => {
            // First check for permission in cookies
            const savedPermissionCookie = getCookie('cameraPermission');
            console.log("Saved permission in cookies:", savedPermissionCookie);
            
            if (savedPermissionCookie === 'granted') {
                console.log("Permission already granted (from cookie), starting camera...");
                startCamera();
            } else {
                const savedPermission = localStorage.getItem('cameraPermission');
                console.log("Saved permission in localStorage:", savedPermission);
    
                if (savedPermission === 'granted') {
                    console.log("Permission already granted (from localStorage), starting camera...");
                    startCamera();
                } else {
                    try {
                        console.log("Requesting camera permission...");
                        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                        console.log("Camera permission granted.");
                        
                        // Stop the stream after getting permission
                        stream.getTracks().forEach(track => track.stop());
                        
                        // Save permission status to localStorage and cookies
                        localStorage.setItem('cameraPermission', 'granted');
                        setCookie('cameraPermission', 'granted', 7);  // cookie valid for 7 days
                        
                        // Start the camera
                        startCamera();
                    } catch (error) {
                        console.error("Error accessing camera:", error);
                        setCameraPermissionDenied(true); // Set permission denied state
                        // Save permission status to localStorage and cookies as denied
                        localStorage.setItem('cameraPermission', 'denied');
                        setCookie('cameraPermission', 'denied', 7);
                    }
                }
            }
        };
    
        checkPermissions();
    
        return () => stopCamera();
    }, []);
    //UNCOMMENT ABOVE TO USE COOKIES AND LOCAL STORAGE//
    
    
    // Update the scale transform of the video element
    useEffect(() => {
        if (videoRef.current) {
            const scale = scaleFactor * additionalZoom;
            videoRef.current.style.transform = `scale(${scale})`;
        }
    }, [scaleFactor, additionalZoom]);

    // Add a refresh function
    const refreshCamera = useCallback(() => {
        console.log("Refreshing camera...");
        stopCamera();
        setTimeout(() => {
            startCamera();
        }, 1000);
    }, [startCamera]);

    
    // Check if inferences are running
    useEffect(() => {
        const checkInferenceStatus = () => {
            if (videoRef.current) {
                const currentIsInferenceRunning = isInferenceRunning; // Use the state directly
                // Only start the timer if the camera is ready
                if (isCameraReady) {
                    const currentTime = Date.now();
                    const timeSinceLastInference = currentTime - lastInferenceTimeRef.current;
                    if (timeSinceLastInference > 7000) { // Adjust this value as needed
                        setIsInferenceRunning(false);
                    }
                }
            }
        };

        const intervalId = setInterval(checkInferenceStatus, 1500); // Check every second

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [isCameraReady, isInferenceRunning]); // Add isCameraReady to the dependency array

    // Expose isInferenceRunning and lastInferenceTime to parent component
    useEffect(() => {
        if (ref.current) {
            ref.current.isInferenceRunning = isInferenceRunning;
            ref.current.getLastInferenceTime = () => lastInferenceTimeRef.current;
            ref.current.isCameraReady = isCameraReady; // Ensure this line is present
        }
    }, [isInferenceRunning, isCameraReady]); // Add isCameraReady to the dependency array

    // Removing this entire block as it was stopping the vision from working
    // Add this useEffect for periodic cleanup
    // useEffect(() => {
    //     const cleanupInterval = setInterval(() => {
    //         // Only run cleanup if we're not in the middle of inference
    //         if (!isInferenceRunning) {
    //             const before = tf.memory();
                
    //             try {
    //                 tf.engine().startScope();
    //                 tf.engine().endScope();
    //                 tf.engine().disposeVariables();
                    
    //                 const after = tf.memory();
                    
    //                 if (before.numTensors - after.numTensors > 0) {
    //                     console.log('Cleanup recovered tensors:', {
    //                         before: before.numTensors,
    //                         after: after.numTensors,
    //                         recovered: before.numTensors - after.numTensors
    //                     });
    //                 }
    //             } catch (error) {
    //                 console.warn('Cleanup skipped:', error);
    //             }
    //         }
    //     }, 30000); // Every 30 seconds
    
    //     return () => clearInterval(cleanupInterval);
    // }, [isInferenceRunning]); // Add dependency on inference state

    // Add the useEffect for camera permission check
    useEffect(() => {
        const checkCameraPermission = async () => {
            try {
                const permissionStatus = await navigator.permissions.query({ name: 'camera' });
                setIsCameraPermissionGranted(permissionStatus.state === 'granted');
                
                // Listen for permission changes
                permissionStatus.addEventListener('change', (e) => {
                    setIsCameraPermissionGranted(e.target.state === 'granted');
                });
            } catch (error) {
                console.log("Permission query not supported, falling back to stored permission");
                // Fallback to stored permission from cookies/localStorage
                const savedPermission = getCookie('cameraPermission') || localStorage.getItem('cameraPermission');
                setIsCameraPermissionGranted(savedPermission === 'granted');
            }
        };
        
        checkCameraPermission();
    }, []);

    // Add the useEffect for the final error message delay
    useEffect(() => {
        let timeoutId;
        if (!isCameraPermissionGranted && !cameraError && !cameraPermissionDenied) {
            timeoutId = setTimeout(() => {
                setShowFinalErrorMessage(true);
            }, 4000);
        } else {
            setShowFinalErrorMessage(false);
        }
        
        return () => clearTimeout(timeoutId);
    }, [isCameraPermissionGranted, cameraError, cameraPermissionDenied]);

    return (
        <div>
            <video 
                ref={videoRef} 
                autoPlay 
                playsInline
                muted 
                className={`video-element ${isCameraReady ? "video-visible" : "video-hidden"}`}
                controls={false}  // Add this  //IF THESE AND CSS MEASURES DONT WORK, THERE ARE SOME HS MEASURE TO PROGRAMMATICALLY PLAY THE VIDEO AFTER THE STREAM IS SET
                playsinline="true"  // Add this (redundant but sometimes helps)
                webkit-playsinline="true"  // Add this for older iOS versions
            ></video>
            
            {/* Show specific messages based on conditions */}
            {!isCameraReady && !cameraError && !cameraPermissionDenied ? (
                <div className="loading-message">{content.loading}</div>
            ) : initializationTimeoutReached && !isCameraReady && !cameraError && !isWaitingForPermission ? (
                <div className="timeout-message">
                    <div>{content.timeout}</div>
                </div>
            ) : cameraError && !isWaitingForPermission ? (
                <div className="error-message">
                    <div>{content.error}</div>
                </div>
            ) : cameraPermissionDenied ? (
                <div className="error-message">
                    <div>{content.permissionDenied}</div>
                </div>
            ) : !isCameraPermissionGranted ? (
                <div className="error-message">
                    {showFinalErrorMessage && (
                        <div>{content.permissionDenied}</div>
                    )}
                </div>
            ) : (
                <div className="error-message"></div> //KEEPING THIS BLANK FOR NOW UNTIL FIXED "Please refresh the page and ensure camera access is enabled in your browser settings."
            )}
            
            <div className="zoom-buttons">
                {[1, 2, 3].map((zoom) => (
                    <button 
                        key={zoom} 
                        className={additionalZoom === zoom ? 'active' : 'not-active'} 
                        onClick={(e) => setAdditionalZoom(zoom)}
                    >
                        {zoom}{content.zoom}
                    </button>
                ))}
            </div>
            {/* THE BELOW IS USED FOR TESTING */}
            {/* {processedImageSrc && (
                <div className="processed-image-container">
                    <img src={processedImageSrc} alt="Processed" />
                </div>
            )} */}
            <div className="white-box"></div>
        </div>
    );
});

export default VideoCapture;
