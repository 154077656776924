import React from 'react';
import './PopupStyles.css';

const LanguageSelector = ({ onLanguageSelect, selectedLanguage }) => {
  const handleLanguageSelect = (language) => {
    onLanguageSelect(language);
    onLanguageSelect('continue');
  };

  return (
    <div className="consent-overlay">
      <div className="consent-content">
        <div className="consent-content-inner language-selector">
          <h2>Croeso i Amgueddfa Cymru!<br/>Welcome to National Museums Wales!</h2>
          <p className="language-instruction">
            <span>Dewiswch eich iaith</span><br/>
            <span>Please choose your language</span>
          </p>
          
          <div className="language-buttons">
            <button 
              className={`language-button ${selectedLanguage === 'Welsh' ? 'selected' : ''}`}
              onClick={() => handleLanguageSelect('Welsh')}
            >
              <div className="button-content">
                <img src="/welsh-flag.png" alt="Welsh flag" className="flag-image" />
                <span className="language-text">Cymraeg</span>
              </div>
            </button>
            
            <button 
              className={`language-button ${selectedLanguage === 'English' ? 'selected' : ''}`}
              onClick={() => handleLanguageSelect('English')}
            >
              <div className="button-content">
                <img src="/english-flag.png" alt="English flag" className="flag-image" />
                <span className="language-text">English</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;