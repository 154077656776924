import React from 'react';
import './PopupStyles.css';
import { useContent } from '../hooks/useContent';

const ConsentPopup = ({ hasLanguageSelected }) => {
  const content = useContent('consent');
  
  if (!content) return null; // Handle case when content is not available

  return (
    <div className={`consent-overlay ${!hasLanguageSelected ? 'disabled-overlay' : ''}`}>
      <div className="consent-content">
        <div className="consent-content-inner">
          <h2>{content.title}</h2>
          <div className="consent-text">
            <p>{content.subtitle}</p>
            <ul>
              {content.bulletPoints.map((point, index) => (
                <li key={index}>
                  <strong>{point.title}</strong> {point.text}
                </li>
              ))}
            </ul>
            <p>
              {content.links.prefix}{' '}
              <a href="https://tell-me.ai/privacy" target="_blank" rel="noopener noreferrer">
                {content.links.privacy}
              </a>
              {' '}{content.links.connector}{' '}
              <a href="https://tell-me.ai/terms" target="_blank" rel="noopener noreferrer">
                {content.links.terms}
              </a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsentPopup;