import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useContent } from '../hooks/useContent';
import './PopupStyles.css';

const ValidationPopup = ({ onClose, validationData, userLanguage }) => {
  const content = useContent('validation');

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // Create a function to safely render HTML
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="popup-title">{content.title}</h2>
        <div 
          className="popup-subtitle"
          dangerouslySetInnerHTML={createMarkup(content.subtitle)}
        />
        <div className="popup-text validation-sources">
          {validationData.relevant_info.map((info, index) => (
            <p key={index} className="source-item">
              <strong>{content.source} {index + 1}:</strong> "{info}"
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ValidationPopup;