import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; //removed Link from here for now, maybe need it later
import Object from './Pages/Object';
import Personalisation from './Pages/Personalisation';
import ConsentPopup from './Components/ConsentPopup';
import { v4 as uuidv4 } from 'uuid';
import ExpiryFeedback from './Components/ExpiryFeedback';
import LanguageSelector from './Components/LanguageSelector';
import NPSPopup from './Components/NPSPopup';

function App() {
  // const [userLanguage, setUserLanguage] = useState(localStorage.getItem('userLanguage') || 'English');
  const [userLanguage, setUserLanguage] = useState(() => {
    if (process.env.REACT_APP_LOCATION === 'MT') {
      localStorage.setItem('userLanguage', 'English'); // Force English in localStorage for MT
      return 'English';
    }
    return localStorage.getItem('userLanguage') || '';
  });
  const [userType, setUserType] = useState(localStorage.getItem('userType') || 'adult');
  const [openInput, setOpenInput] = useState(localStorage.getItem('openInput') || '');
  const [showConsent, setShowConsent] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
  const [isExpired, setIsExpired] = useState(false);
  const [hasConsented, setHasConsented] = useState(localStorage.getItem('hasConsented') === 'true');
  const [showLanguageSelector, setShowLanguageSelector] = useState(
    process.env.REACT_APP_LOCATION === 'RLM' && !localStorage.getItem('userLanguage')
  );
  const [showNPSPopup, setShowNPSPopup] = useState(false);
  const [hasShownNPSPopup, setHasShownNPSPopup] = useState(
    localStorage.getItem('hasShownNPSPopup') === 'true'
  );
  const isReturningToApp = useRef(false);

  // All useEffects together
  useEffect(() => {
    if (!userId) {
      const newUserId = uuidv4();
      setUserId(newUserId);
      localStorage.setItem('userId', newUserId);
    }
  }, []);

  useEffect(() => {
    const hasConsented = localStorage.getItem('hasConsented');
    if (hasConsented !== 'true') {
      setShowConsent(true);
    }
  }, []);

  // Expiry effect
  // useEffect(() => {
  //   // Run expiry check for both MT and RLM locations
  //   if (process.env.REACT_APP_LOCATION === 'MT' || process.env.REACT_APP_LOCATION === 'RLM') {
  //     // Check if this is the first visit
  //     const initialAccessTime = localStorage.getItem('initialAccessTime');
  //     if (!initialAccessTime) {
  //       localStorage.setItem('initialAccessTime', Date.now().toString());
  //     }

  //     // Function to check if session is expired
  //     const checkExpiration = () => {
  //       const initialTime = localStorage.getItem('initialAccessTime');
  //       if (initialTime) {
  //         const timeElapsed = Date.now() - parseInt(initialTime);
  //         const timeout = 120 * 60 * 1000; // 120 minutes timeout
          
  //         if (timeElapsed > timeout) {
  //           setIsExpired(true);
  //         }
  //       }
  //     };

  //     checkExpiration();
  //     const intervalId = setInterval(checkExpiration, 10000);
  //     return () => clearInterval(intervalId);
  //   }
  // }, []);
  //// COMMENT OUT ABOVE WHEN TESTING ////

  // NPS effect
  useEffect(() => {
    // Run for both MT and RLM locations
    if (!hasShownNPSPopup) {
      const initialTime = localStorage.getItem('initialAccessTime');
      
      if (initialTime) {
        // Initial delay before starting to check
        const delayTimeout = setTimeout(() => {
          const checkInterval = setInterval(() => {
            const timeElapsed = Date.now() - parseInt(initialTime);
            const timeThreshold = process.env.REACT_APP_LOCATION === 'MT' 
              ? 8 * 60 * 1000  // 8 minutes for MT
              : 14 * 60 * 1000; // 17 minutes for RLM
            
            if (timeElapsed >= timeThreshold) {
              clearInterval(checkInterval);
              
              if (!isReturningToApp.current) {
                // First time reaching 8 minutes while active
                setShowNPSPopup(true);
              }
            }
          }, 1000);

          return () => clearInterval(checkInterval);
        }, 10000); // 10 second initial delay

        return () => clearTimeout(delayTimeout);
      }
    }
  }, [hasShownNPSPopup]);

  // Separate visibility change handler
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        isReturningToApp.current = true;
        const initialTime = localStorage.getItem('initialAccessTime');
        if (initialTime) {
          const timeElapsed = Date.now() - parseInt(initialTime);
          const eightMinutes = 8 * 60 * 1000; // 8 minutes in milliseconds
          
          if (timeElapsed >= eightMinutes && !hasShownNPSPopup) {
            // Hide popup when returning
            setShowNPSPopup(false);
            // Add 15 second delay before showing popup when returning to app
            setTimeout(() => {
              if (!hasShownNPSPopup) {  // Double check it hasn't been shown
                setShowNPSPopup(true);
              }
            }, 12000);
          }
        }
      } else {
        // Hide popup when leaving the app
        setShowNPSPopup(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [hasShownNPSPopup]);

  const handleConsent = () => {
    localStorage.setItem('hasConsented', 'true');
    setHasConsented(true);
  };

  const handleLanguageChange = (event) => {
    const language = event.target.value;
    localStorage.setItem('userLanguage', language);
    setUserLanguage(language);
  };

  
  
  useEffect(() => {
    // Prevent zoom on double tap
    document.addEventListener('touchstart', (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    }, { passive: false });

    // Prevent zoom on pinch
    document.addEventListener('touchmove', (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    }, { passive: false });

    // Prevent zoom on double tap
    let lastTouchEnd = 0;
    document.addEventListener('touchend', (event) => {
      const now = (new Date()).getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    }, { passive: false });

    // Prevent zoom using keyboard shortcuts or mouse wheel
    document.addEventListener('keydown', (event) => {
      if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-')) {
        event.preventDefault();
      }
    });

    document.addEventListener('wheel', (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    }, { passive: false });
  }, []);


  // ## LOG INITIAL ACCESS ##
  useEffect(() => {
    const logInitialAccess = async () => {
      try {
        // Safely get camera permission
        let cameraPermissionStatus = 'unknown';
        try {
          if (navigator.permissions) {
            const permissionStatus = await navigator.permissions.query({ name: 'camera' });
            cameraPermissionStatus = permissionStatus.state;
          } else {
            cameraPermissionStatus = 'permissions_api_not_available';
          }
        } catch (permError) {
          cameraPermissionStatus = 'query_not_supported';
        }
  
        // Safely get connection info
        let connectionInfo = null;
        try {
          if (navigator.connection) {
            connectionInfo = {
              effectiveType: navigator.connection.effectiveType || 'unknown',
              downlink: navigator.connection.downlink || null,
              rtt: navigator.connection.rtt || null,
            };
          }
        } catch (connError) {
          connectionInfo = null;
        }
  
        const diagnosticInfo = {
          // Use optional chaining and fallbacks for everything
          userAgent: navigator?.userAgent || 'unknown',
          platform: navigator?.platform || 'unknown',
          browserLanguage: navigator?.language || 'unknown',
          vendor: navigator?.vendor || 'unknown',
          
          cameraPermission: cameraPermissionStatus,
          
          screenResolution: window?.screen ? 
            `${window.screen.width || 0}x${window.screen.height || 0}` : 
            'unknown',
          windowSize: window ? 
            `${window.innerWidth || 0}x${window.innerHeight || 0}` : 
            'unknown',
          
          connection: connectionInfo,
          location: process.env.REACT_APP_LOCATION || 'unknown'
          // Removed timestamp from here
        };
  
        // Only attempt to send if fetch is available
        if (typeof fetch === 'function') {
          await fetch('https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/initial-login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              userId: userId,
              diagnosticInfo,
              type: 'initial_access'
            })
          });
        }
  
      } catch (error) {
        // Just log the error, don't let it affect the app
        console.error('Error logging initial access:', error);
      }
    };
  
    if (userId) {
      // Wrap in try-catch to ensure app continues even if logging fails
      try {
        logInitialAccess();
      } catch (error) {
        console.error('Failed to log initial access:', error);
      }
    }
  }, [userId]);



  //// COMMENT OUT BELOW WHEN TESTING ////
  // Only render ExpiryFeedback for MT
  if (isExpired && process.env.REACT_APP_LOCATION === 'MT') {
    return <ExpiryFeedback userId={userId} />;
  }
  ////COMMENT OUT ABOVE WHEN TESTING ////
  

  const handleInitialLanguageSelect = (language) => {
    if (language === 'continue') {
      setShowLanguageSelector(false);
      return;
    }
    localStorage.setItem('userLanguage', language);
    setUserLanguage(language);
  };

  const hasLanguageSelected = 
    process.env.REACT_APP_LOCATION === 'MT' || 
    (userLanguage && userLanguage !== '');

  const handleNPSClose = () => {
    setShowNPSPopup(false);
    setHasShownNPSPopup(true);
    localStorage.setItem('hasShownNPSPopup', 'true');
  };

  return (
    <Router>
      {/* <nav> */}
        {/* Navigation links to different routes */}
        {/* <Link to="/scan">Scan</Link> */}
        {/* <Link to="/personalisation">Personalisation</Link> */}
        {/* <Link to="/conversation">Conversation</Link> */} 
      {/* </nav> */}
      {showLanguageSelector && (
        <LanguageSelector 
          onLanguageSelect={handleInitialLanguageSelect}
          selectedLanguage={userLanguage}
        />
      )}
      {!hasConsented && !showLanguageSelector && hasLanguageSelected && (
        <ConsentPopup hasLanguageSelected={hasLanguageSelected} />
      )}
      <Routes>
        <Route exact path="/" element={
          <Object 
            userLanguage={userLanguage} 
            setUserLanguage={setUserLanguage} 
            userType={userType} 
            openInput={openInput} 
            userId={userId} 
            onConsent={handleConsent} 
            hasConsented={hasConsented && hasLanguageSelected} 
          />
        } />
        <Route path="/scan" element={<Object userLanguage={userLanguage} userType={userType} openInput={openInput} userId={userId} onConsent={handleConsent} hasConsented={hasConsented} />} />
        <Route path="/personalisation" element={<Personalisation userLanguage={userLanguage} userType={userType} setUserLanguage={setUserLanguage} setUserType={setUserType} openInput={openInput} setOpenInput={setOpenInput} />} />
      </Routes>
      {showNPSPopup && <NPSPopup onClose={handleNPSClose} userId={userId} />}
    </Router>
  );
}

export default App;
