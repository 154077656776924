import React from 'react';
import './PopupStyles.css';
import { useContent } from '../hooks/useContent';

const NPSPopup = ({ onClose, userId }) => {
  const content = useContent('npsPopup');

  const handleRating = async (value) => {
    try {
      await fetch('https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/mt-feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          questions: [
            {
              question: content.question,
              answer: value,
              type: "nps"
            }
          ],
          metadata: {
            userId: userId,
            location: process.env.REACT_APP_LOCATION,
            userLanguage: localStorage.getItem('userLanguage') || 'English',
            timestamp: new Date().toISOString()
          }
        })
      });
    } catch (error) {
      console.error('Error sending NPS feedback:', error);
    }
    onClose();
  };

  // Don't render if content is not available
  if (!content) return null;

  return (
    <div className="nps-overlay" onClick={(e) => e.target === e.currentTarget && onClose()}>
      <div className="nps-content">
        <div className="nps-container">
          <p className="nps-question">{content.question}</p>
          <div className="nps-rating-container">
            <div className="nps-rating-labels">
              <span className="nps-rating-label">{content.labels.min}</span>
              <span className="nps-rating-label">{content.labels.max}</span>
            </div>
            <div className="nps-rating-buttons">
              {[...Array(11)].map((_, index) => (
                <button
                  key={index}
                  className="nps-rating-button"
                  onClick={() => handleRating(index)}
                >
                  {index}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NPSPopup;