const RLM_THRESHOLDS = {
    "1": 0.7,
    "2": 0.5,
    "3.0": 0.5,
    "3.1": 0.57,
    "3.2": 0.57,
    "4.0": 0.5,
    "4.1": 0.57,
    "4.2": 0.57,
    "4.3": 0.57,
    "4.4": 0.57,
    "5.0": 0.5,
    "5.1": 0.57,
    "5.2": 0.57,
    "5.3": 0.57,
    "5.4": 0.57,
    "6.0": 0.52,
    "6.1": 0.57,
    "6.2.0": 0.57,
    "6.2.1": 0.57,
    "6.2.2": 0.57,
    "6.3.0": 0.57,
    "6.3.1": 0.57,
    "6.3.2": 0.57,
    "6.4.0": 0.57,
    "6.4.1": 0.57,
    "6.4.2": 0.57,
    "6.5": 0.57,
    "6.6.0": 0.57,
    "6.6.1": 0.57,
    "6.6.2": 0.57,
    "6.7": 0.57,
    "7.0": 0.5,
    "7.1": 0.57,
    "7.2": 0.57,
    "7.3": 0.57,
    "7.4": 0.57,
    "7.5": 0.57,
    "7.6": 0.57,
    "7.7": 0.57,
    "7.8": 0.57,
    "8.0": 0.5,
    "8.1": 0.57,
    "8.2": 0.57,
    "8.3": 0.57,
    "8.4": 0.57,
    "8.5": 0.57,
    "8.6": 0.57,
    "9.0": 0.5,
    "9.1": 0.57,
    "9.2": 0.57,
    "9.3": 0.57,
    "9.4": 0.57,
    "10.0": 0.5,
    "10.1": 0.57,
    "10.2": 0.57,
    "11": 0.57,
    "12": 0.57,
    "13": 0.57,
    "14": 0.54,
    "15": 0.54,
    "16.0": 0.5,
    "16.1": 0.57,
    "16.2": 0.57,
    "17": 0.54,
    "18": 0.54,
    "19": 0.54,
    "20.0": 0.52,
    "20.1.0": 0.5,
    "20.1.1.0": 0.5,
    "20.1.1.1": 0.57,
    "20.1.1.2": 0.57,
    "20.1.2.0": 0.5,
    "20.1.2.1": 0.57,
    "20.1.2.2": 0.57,
    "20.1.3.0": 0.5,
    "20.1.3.1": 0.57,
    "20.1.3.2": 0.57,
    "20.2": 0.5,
    "20.3": 0.5,
    "20.4": 0.5,
    "20.5": 0.5,
    "20.6.0": 0.57,
    "20.6.1": 0.5,
    "20.6.2": 0.57,
    "21.0": 0.5,
    "21.1": 0.57,
    "21.2": 0.57,
    "22.0": 0.54,
    "22.1": 0.54,
    "22.2": 0.54,
    "23.0": 0.52,
    "23.1": 0.57,
    "23.2": 0.57,
    "23.3": 0.57,
    "24.0": 0.52,
    "24.1": 0.57,
    "24.2": 0.57,
    "24.3": 0.57,
    "24.4": 0.57,
    "24.5": 0.57,
    "24.6.0": 0.57,
    "24.6.1": 0.57,
    "24.6.2": 0.57,
    "24.6.3": 0.57,
    "24.7.0": 0.5,
    "24.7.1": 0.5,
    "24.7.2": 0.5,
    "24.7.3": 0.5,
    "24.7.4": 0.5,
    "24.7.5": 0.5,
    "25": 0.54,
    "26": 0.54,
    "27": 0.54,
    "28": 0.54,
    "29": 0.5,
    "30.0": 0.54,
    "30.1": 0.54,
    "30.2": 0.54,
    "31": 0.54,
    "32": 0.54,
    "33.0": 0.52,
    "33.1": 0.57,
    "33.2": 0.57,
    "33.3.0": 0.54,
    "33.3.1": 0.54,
    "33.3.2": 0.54,
    "33.4": 0.57,
    "33.5": 0.57,
    "33.6": 0.57,
    "33.7": 0.57,
    "34": 0.54,
    "35.0": 0.52,
    "35.1.0": 0.57,
    "35.1.1": 0.54,
    "35.1.2": 0.54,
    "35.1.3": 0.54,
    "35.1.4": 0.54,
    "35.1.5": 0.54,
    "35.2": 0.57,
    "35.3": 0.57,
    "35.4": 0.57,
    "35.5": 0.57,
    "35.6": 0.57,
    "35.7.0": 0.57,
    "35.7.1": 0.52,
    "35.7.2": 0.52,
    "35.7.3": 0.52,
    "35.7.4": 0.52,
    "35.8": 0.52,
    "36": 0.54,
    "37": 0.54,
    "38": 0.54,
    "39": 0.54,
    "40.0": 0.54,
    "40.1.0": 0.54,
    "40.1.1": 0.54,
    "40.1.2": 0.54,
    "40.2": 0.54,
    "41.0": 0.54,
    "41.1": 0.57,
    "41.2": 0.57,
    "42": 0.5,
    "43.0": 0.54,
    "43.1": 0.5,
    "43.2": 0.54,
    "43.3": 0.54,
    "43.4": 0.54,
    "43.5": 0.54,
    "43.6": 0.54,
    "43.7": 0.54,
    "43.8": 0.54,
    "43.9": 0.54,
    "44.0": 0.54,
    "45.0": 0.57,
    "45.1": 0.57,
    "45.2": 0.57,
    "45.3": 0.57,
    "45.4": 0.57,
    "46": 0.57,
    "47": 0.57,
    "48.0": 0.57,
    "48.1": 0.57,
    "48.2": 0.57,
    "48.3": 0.57,
    "49": 0.57,
    "50": 0.75,
    "51.0": 0.5,
    "51.1": 0.5,
    "51.2": 0.5,
    "52": 0.54,
    "53": 0.54,
    "54.0": 0.52,
    "54.1.0": 0.57,
    "54.1.1": 0.57,
    "54.2": 0.57,
    "54.3": 0.54,
    "54.4": 0.54,
    "54.5.0": 0.54,
    "54.5.1": 0.57,
    "54.6": 0.57,
    "54.7": 0.57,
    "54.8": 0.57,
    "54.9": 0.57,
    "54.10.0": 0.5,
    "54.10.1": 0.57,
    "54.11": 0.57,
    "54.12": 0.5,
    "54.13": 0.5,
    "54.14": 0.5,
    "54.15": 0.5,
    "54.16": 0.5,
    "54.17": 0.5,
    "54.18": 0.5,
    "55.0": 0.47,
    "55.1": 0.47,
    "55.2.0": 0.57,
    "55.2.1": 0.57,
    "55.2.2": 0.57,
    "55.3": 0.57,
    "55.4.0": 0.57,
    "55.4.1": 0.57,
    "55.4.2": 0.57,
    "55.4.3": 0.57,
    "55.4.4": 0.57,
    "55.5.0": 0.57,
    "55.5.1": 0.57,
    "55.5.2": 0.57,
    "55.6.0": 0.57,
    "55.6.1": 0.57,
    "55.6.2": 0.57,
    "55.7": 0.57,
    "55.8": 0.48,
    "55.9.0": 0.57,
    "55.9.1": 0.52,
    "55.9.2": 0.52,
    "55.9.3": 0.57,
    "55.10.0": 0.57,
    "55.10.1": 0.57,
    "55.10.2": 0.57,
    "55.11.0": 0.57,
    "55.11.1.0": 0.47,
    "55.11.1.1": 0.57,
    "55.11.1.2": 0.57,
    "55.11.2": 0.57,
    "56.0": 0.47,
    "56.1": 0.49,
    "56.2": 0.49,
    "56.3": 0.52,
    "56.4": 0.49,
    "56.5": 0.49,
    "56.6": 0.52,
    "56.7": 0.49,
    "56.8": 0.49,
    "56.9": 0.49,
    "56.10": 0.49,
    "56.11": 0.49,
    "56.12": 0.49,
    "56.13": 0.49,
    "56.14": 0.49,
    "56.15": 0.49,
    "56.16": 0.49,
    "56.17": 0.49,
    "56.18": 0.52,
    "56.19": 0.49,
    "56.20": 0.49,
    "56.21.0": 0.57,
    "56.21.1": 0.57,
    "56.21.2": 0.57,
    "56.22": 0.49,
    "56.23": 0.57,
    "56.24": 0.49,
    "56.25": 0.49,
    "56.26": 0.49,
    "56.27": 0.49,
    "57.0": 0.47,
    "57.1.0": 0.57,
    "57.1.1": 0.57,
    "57.1.2": 0.57,
    "57.1.3": 0.57,
    "57.2.0": 0.57,
    "57.2.1": 0.52,
    "57.2.2": 0.5,
    "57.2.3": 0.52,
    "57.3.0": 0.57,
    "57.3.1": 0.57,
    "57.4": 0.57,
    "57.5": 0.57,
    "nan": 0.57,
    "57.6.0": 0.5,
    "57.6.1": 0.5,
    "57.6.2": 0.5,
    "57.6.3": 0.5,
    "57.6.4.0": 0.5,
    "57.6.4.1": 0.57,
    "57.7.0": 0.5,
    "57.7.1": 0.5,
    "57.7.2": 0.5,
    "57.7.3": 0.5,
    "57.8.0": 0.57,
    "57.8.1": 0.5,
    "57.8.2": 0.5,
    "57.8.3": 0.5,
    "57.9.0": 0.5,
    "57.9.1.0": 0.5,
    "57.9.1.1": 0.57,
    "57.9.1.2": 0.57,
    "57.9.1.3": 0.57,
    "57.9.2.0": 0.57,
    "57.9.2.1": 0.57,
    "57.9.2.2": 0.57,
    "57.9.3": 0.5,
    "57.9.4": 0.5,
    "57.10.0": 0.48,
    "57.10.1": 0.57,
    "57.10.2": 0.57,
    "57.11.0": 0.57,
    "57.11.1.0": 0.5,
    "57.11.1.1": 0.57,
    "57.11.1.2": 0.57,
    "57.11.2": 0.57,
    "57.11.3": 0.57,
    "57.12.0": 0.57,
    "57.12.1.0": 0.57,
    "57.12.1.1": 0.57,
    "57.12.1.2": 0.57,
    "57.12.2": 0.57,
    "57.12.3.0": 0.57,
    "57.12.3.1": 0.57,
    "57.12.3.2": 0.57,
    "57.12.4": 0.57,
    "57.13.0": 0.57,
    "57.13.1": 0.52,
    "57.13.2": 0.52,
    "57.13.3.0": 0.52,
    "57.13.3.1": 0.57,
    "57.13.3.2": 0.57,
    "57.13.4": 0.52,
    "57.14.0": 0.52,
    "57.14.1": 0.52,
    "57.14.2": 0.52,
    "57.14.3": 0.52,
    "57.14.4": 0.52,
    "57.14.5": 0.52,
    "57.14.6": 0.52,
    "57.14.7": 0.57,
    "58.0": 0.47,
    "58.1.0": 0.57,
    "58.1.1": 0.52,
    "58.1.2": 0.57,
    "58.1.3.0": 0.57,
    "58.1.3.1": 0.57,
    "58.1.3.2": 0.57,
    "58.1.3.3": 0.57,
    "58.2": 0.57,
    "58.3.0": 0.57,
    "58.3.1": 0.48,
    "58.3.2": 0.57,
    "58.4.0": 0.57,
    "58.4.1": 0.48,
    "58.4.2": 0.57,
    "58.5.0": 0.48,
    "58.5.1": 0.57,
    "58.5.2": 0.57,
    "58.6.0": 0.57,
    "58.6.1": 0.57,
    "58.6.2": 0.57,
    "58.6.3": 0.57,
    "58.7.0": 0.57,
    "58.7.1": 0.57,
    "58.7.2": 0.57,
    "58.8.0": 0.57,
    "58.8.1": 0.57,
    "58.8.2": 0.57,
    "58.8.3": 0.57,
    "58.9.0": 0.57,
    "58.9.1": 0.57,
    "58.10": 0.57,
    "58.11.0": 0.57,
    "58.11.1": 0.57,
    "58.12.0": 0.57,
    "58.12.1": 0.48,
    "58.12.2.0": 0.48,
    "58.12.2.1": 0.57,
    "58.12.2.2": 0.57,
    "58.12.3.0": 0.48,
    "58.12.3.1": 0.57,
    "58.12.3.2": 0.57,
    "58.13.0": 0.57,
    "58.13.1": 0.57,
    "58.13.2": 0.57,
    "58.13.3": 0.57,
    "58.14": 0.57,
    "59": 0.5,
    "60.0": 0.47,
    "60.1": 0.57,
    "60.2": 0.57,
    "60.3.0": 0.57,
    "60.3.1": 0.57,
    "60.3.2": 0.57,
    "60.4.0": 0.47,
    "60.4.1": 0.57,
    "60.4.2": 0.57,
    "60.4.3": 0.57,
    "60.4.4": 0.57,
    "60.4.5": 0.57,
    "60.4.6": 0.57,
    "60.4.7": 0.57,
    "60.4.8": 0.57,
    "60.4.9": 0.57,
    "60.5.0": 0.57,
    "60.5.1": 0.57,
    "60.5.2": 0.57,
    "60.6": 0.57,
    "60.7": 0.57,
    "60.8": 0.57,
    "60.9.0": 0.57,
    "60.9.1": 0.57,
    "60.9.2": 0.57,
    "60.9.3": 0.57,
    "60.10": 0.52,
    "60.11.0": 0.57,
    "60.11.1": 0.54,
    "60.11.2": 0.54,
    "60.11.2.1": 0.57,
    "60.11.2.2": 0.57,
    "60.12": 0.54,
    "60.12.1": 0.57,
    "60.12.2": 0.57,
    "60.12.3": 0.57,
    "60.12.4": 0.57,
    "60.12.5": 0.57,
    "60.12.6": 0.57,
    "60.13": 0.57,
    "60.14": 0.57,
    "60.15": 0.57,
    "60.16": 0.57,
    "60.17": 0.57,
    "60.18": 0.57,
    "60.18.1": 0.57,
    "60.18.2": 0.57,
    "60.18.3": 0.57,
    "60.18.4": 0.57,
    "60.18.5": 0.57,
    "60.18.6": 0.57,
    "60.18.7": 0.57,
    "61.0": 0.47,
    "61.1": 0.57,
    "61.2.0": 0.57,
    "61.2.1": 0.57,
    "61.2.2": 0.57,
    "61.3.0": 0.57,
    "61.3.1": 0.57,
    "61.3.2": 0.57,
    "61.4.0": 0.57,
    "61.4.1": 0.57,
    "61.4.2.0": 0.57,
    "61.4.2.1": 0.57,
    "61.4.2.2": 0.57,
    "61.4.2.3": 0.57,
    "61.5": 0.57,
    "61.6.0": 0.57,
    "61.6.1": 0.57,
    "61.6.2": 0.57,
    "61.7.0": 0.57,
    "61.7.1.0": 0.57,
    "61.7.1.1": 0.52,
    "61.7.1.2": 0.52,
    "61.7.2.0": 0.57,
    "61.7.2.1": 0.57,
    "61.7.2.2": 0.57,
    "61.8.0": 0.54,
    "61.8.1": 0.57,
    "61.8.2": 0.57,
    "61.9.0": 0.57,
    "61.9.1": 0.57,
    "61.9.2": 0.57,
    "61.9.3": 0.57,
    "61.9.4.0": 0.57,
    "61.9.4.1": 0.57,
    "61.9.4.2": 0.57,
    "61.9.5": 0.57,
    "61.10.0": 0.57,
    "61.10.1": 0.57,
    "61.10.2": 0.57,
    "61.10.3": 0.57,
    "62.0": 0.55,
    "62.1": 0.54,
    "62.2.0": 0.54,
    "62.2.1": 0.57,
    "62.2.2": 0.57,
    "62.3.0": 0.54,
    "62.3.1": 0.54,
    "62.3.2": 0.54,
    "62.4": 0.54,
    "62.5": 0.54,
    "63.0": 0.55,
    "63.1": 0.55,
    "63.2": 0.55,
    "63.3": 0.55,
    "63.4": 0.55,
    "63.5": 0.55,
    "63.6": 0.55,
    "63.7": 0.55,
    "64.0": 0.55,
    "64.1": 0.57,
    "64.2": 0.57,
    "64.3": 0.57,
    "65.0": 0.47,
    "65.1": 0.49,
    "65.2": 0.49,
    "65.3": 0.49,
    "65.4": 0.52,
    "65.5.0": 0.57,
    "65.5.1": 0.52,
    "65.5.2": 0.52,
    "65.5.3": 0.52,
    "65.6": 0.49,
    "65.7": 0.49,
    "65.8": 0.49,
    "65.9": 0.49,
    "65.10": 0.49,
    "65.11": 0.49,
    "65.12": 0.49,
    "65.13": 0.57,
    "65.14": 0.49,
    "65.15": 0.52,
    "65.16": 0.52,
    "65.17": 0.52,
    "65.18": 0.52,
    "65.19": 0.49,
    "65.20": 0.49,
    "65.21": 0.49,
    "65.22": 0.49,
    "65.23": 0.54,
    "65.24": 0.54,
    "65.25": 0.52,
    "65.26": 0.52,
    "65.27": 0.49,
    "66.0": 0.47,
    "66.1": 0.49,
    "66.2": 0.49,
    "66.3": 0.49,
    "66.4": 0.49,
    "66.5": 0.49,
    "66.6": 0.49,
    "66.7": 0.49,
    "66.8": 0.52,
    "66.9": 0.49,
    "66.10": 0.49,
    "66.11": 0.49,
    "66.12": 0.49,
    "66.13": 0.49,
    "66.14": 0.49,
    "66.15": 0.49,
    "66.16.0": 0.49,
    "66.16.1": 0.57,
    "66.16.2": 0.57,
    "66.17": 0.49,
    "66.18": 0.49,
    "66.19": 0.49,
    "66.20": 0.49,
    "66.21": 0.49,
    "66.22": 0.49,
    "66.23": 0.49,
    "66.24": 0.49,
    "66.25.0": 0.57,
    "66.25.1": 0.52,
    "66.25.2": 0.52,
    "67.0": 0.47,
    "67.1": 0.52,
    "67.2.0": 0.57,
    "67.2.1": 0.52,
    "67.2.2": 0.52,
    "67.2.3": 0.52,
    "67.2.4": 0.52,
    "67.3.0": 0.57,
    "67.3.1": 0.52,
    "67.3.2": 0.57,
    "67.3.3": 0.52,
    "67.3.4": 0.52,
    "67.3.5": 0.52,
    "67.4": 0.52,
    "67.5.0": 0.52,
    "67.5.1": 0.52,
    "67.5.2": 0.52,
    "67.5.3": 0.52,
    "67.5.4": 0.52,
    "67.5.5": 0.52,
    "67.5.6": 0.52,
    "67.6.0": 0.57,
    "67.6.1": 0.52,
    "67.6.2": 0.52,
    "67.6.3": 0.52,
    "67.6.4": 0.52,
    "67.6.5": 0.52,
    "67.7.0": 0.57,
    "67.7.1": 0.52,
    "67.7.2": 0.52,
    "67.7.3": 0.52,
    "67.7.4": 0.52,
    "67.7.5": 0.52,
    "67.7.6": 0.52,
    "67.7.7": 0.52,
    "67.7.8": 0.52,
    "67.7.9": 0.52,
    "67.7.10": 0.52,
    "67.7.11": 0.52,
    "67.8": 0.52,
    "68.0": 0.47,
    "68.1": 0.57,
    "68.2.0": 0.57,
    "68.2.1": 0.54,
    "68.2.2": 0.54,
    "68.2.3": 0.54,
    "68.3": 0.57,
    "68.4.0": 0.57,
    "68.4.1.0": 0.57,
    "68.4.1.1": 0.54,
    "68.4.1.2": 0.49,
    "68.4.2.0": 0.57,
    "68.4.2.1": 0.54,
    "68.4.2.2": 0.54,
    "68.4.2.3": 0.54,
    "68.5": 0.47,
    "68.6.0": 0.57,
    "68.6.1": 0.54,
    "68.6.2": 0.54,
    "68.7": 0.57,
    "68.8.0": 0.57,
    "68.8.1": 0.57,
    "68.8.2": 0.52,
    "68.8.3": 0.57,
    "68.9.0": 0.57,
    "68.9.1": 0.57,
    "68.9.2": 0.57,
    "68.9.2.1": 0.57,
    "68.9.2.2": 0.57,
    "68.9.3": 0.57,
    "68.10.0": 0.57,
    "68.10.1": 0.57,
    "68.10.1.1": 0.57,
    "68.10.1.2": 0.57,
    "68.10.1.3": 0.57,
    "68.10.1.4": 0.57,
    "68.10.2": 0.57,
    "68.10.2.1": 0.57,
    "68.10.2.2": 0.57,
    "68.11.0": 0.47,
    "68.11.1": 0.54,
    "68.11.2": 0.54,
    "68.12": 0.54,
    "68.12.1": 0.57,
    "68.12.2": 0.57,
    "68.13.0": 0.57,
    "68.13.1": 0.52,
    "68.13.2": 0.54,
    "68.13.3": 0.57,
    "68.13.3.1": 0.57,
    "68.13.3.2": 0.57,
    "68.13.3.3": 0.57,
    "68.14": 0.52,
    "68.15.0": 0.52,
    "68.15.1": 0.52,
    "68.15.2": 0.52,
    "69.0": 0.47,
    "69.1": 0.57,
    "69.2": 0.49,
    "69.3": 0.52,
    "69.4.0": 0.57,
    "69.4.1": 0.57,
    "69.4.2": 0.57,
    "69.5": 0.57,
    "69.6.0": 0.57,
    "69.6.1.0": 0.57,
    "69.6.1.1": 0.57,
    "69.6.1.2": 0.57,
    "69.6.1.3": 0.57,
    "69.6.2": 0.57,
    "69.7.0": 0.57,
    "69.7.1.0": 0.57,
    "69.7.1.1": 0.52,
    "69.7.1.2": 0.52,
    "69.7.1.3": 0.52,
    "69.7.2": 0.52,
    "69.8.0": 0.57,
    "69.8.1": 0.52,
    "69.8.2": 0.57,
    "69.9": 0.57,
    "69.10.0": 0.57,
    "69.10.1": 0.57,
    "69.10.2.0": 0.57,
    "69.10.2.1": 0.57,
    "69.10.2.2": 0.57,
    "69.11.0": 0.57,
    "69.11.1.0": 0.57,
    "69.11.1.1": 0.57,
    "69.11.1.2": 0.57,
    "69.11.1.3": 0.57,
    "69.11.1.4": 0.57,
    "69.11.2": 0.57,
    "69.11.3": 0.57,
    "70.0": 0.47,
    "70.1": 0.49,
    "70.2": 0.49,
    "70.3": 0.49,
    "70.4": 0.49,
    "70.5": 0.49,
    "70.6": 0.49,
    "70.7": 0.49,
    "70.8": 0.49,
    "70.9": 0.49,
    "70.10": 0.49,
    "70.11": 0.49,
    "70.12": 0.49,
    "70.13": 0.49,
    "70.14.0": 0.49,
    "70.14.1": 0.49,
    "70.14.2": 0.49,
    "70.15": 0.49,
    "70.16": 0.49,
    "70.17": 0.49,
    "70.18": 0.49,
    "70.19.0": 0.57,
    "70.19.1": 0.57,
    "70.19.2": 0.57,
    "70.19.3": 0.52,
    "70.20": 0.49,
    "70.21": 0.52,
    "70.22.0": 0.57,
    "70.22.1": 0.49,
    "70.22.2": 0.49,
    "70.23": 0.49,
    "70.24": 0.57,
    "71.0": 0.47,
    "71.1": 0.57,
    "71.2.0": 0.54,
    "71.2.1": 0.57,
    "71.2.2": 0.57,
    "71.2.3": 0.57,
    "71.2.4": 0.57,
    "71.2.5": 0.57,
    "71.3": 0.57,
    "71.4": 0.49,
    "71.5": 0.49,
    "71.6": 0.49,
    "71.7": 0.57,
    "71.8": 0.57,
    "71.9.0": 0.57,
    "71.9.1": 0.49,
    "71.9.2": 0.52,
    "72.0": 0.47,
    "72.1": 0.49,
    "72.2.0": 0.57,
    "72.2.1": 0.52,
    "72.2.2.0": 0.57,
    "72.2.2.1": 0.57,
    "72.2.2.2": 0.57,
    "72.2.2.3": 0.57,
    "72.3.0": 0.57,
    "72.3.1.0": 0.57,
    "72.3.1.1": 0.52,
    "72.3.1.2": 0.52,
    "72.3.1.3": 0.52,
    "72.3.1.4": 0.52,
    "72.3.2": 0.48,
    "72.4": 0.57,
    "72.5.0": 0.57,
    "72.5.1": 0.57,
    "72.5.2": 0.57,
    "72.5.3": 0.57,
    "72.5.4": 0.57,
    "72.6": 0.57,
    "72.7": 0.57,
    "72.8.0": 0.57,
    "72.8.1": 0.57,
    "72.8.2": 0.57,
    "72.9.0": 0.57,
    "72.9.1": 0.49,
    "72.9.2": 0.49,
    "72.9.3.0": 0.57,
    "72.9.3.1": 0.57,
    "72.9.3.2": 0.57,
    "72.10.0": 0.57,
    "72.10.1": 0.57,
    "72.10.2": 0.57,
    "72.11.0": 0.57,
    "72.11.1.0": 0.57,
    "72.11.1.1": 0.57,
    "72.11.1.2": 0.57,
    "72.11.2": 0.52,
    "72.11.3": 0.52,
    "72.12": 0.52,
    "72.13.0": 0.57,
    "72.13.1": 0.52,
    "72.13.2": 0.52,
    "73.0": 0.47,
    "73.1.0": 0.57,
    "73.1.1": 0.57,
    "73.1.2": 0.57,
    "73.2.0": 0.57,
    "73.2.1": 0.57,
    "73.2.2": 0.57,
    "73.3": 0.52,
    "73.4.0": 0.48,
    "73.4.1": 0.48,
    "73.4.2": 0.57,
    "73.4.3.0": 0.57,
    "73.4.3.1": 0.52,
    "73.4.3.2": 0.52,
    "73.5.0": 0.52,
    "73.5.1": 0.52,
    "73.5.2": 0.52,
    "73.5.3": 0.52,
    "73.5.4": 0.57,
    "73.6": 0.49999999999999994,
    "73.7": 0.49999999999999994,
    "73.8": 0.49999999999999994,
    "73.9.0": 0.57,
    "73.9.1": 0.57,
    "73.9.2": 0.57,
    "73.9.3": 0.52,
    "73.9.4": 0.5,
    "73.9.5": 0.5,
    "73.10.0": 0.41999999999999993,
    "73.10.1": 0.41999999999999993,
    "73.10.2": 0.41999999999999993,
    "73.11.0": 0.57,
    "73.11.1": 0.57,
    "73.11.2": 0.52,
    "73.11.3": 0.57,
    "73.11.4": 0.57,
    "73.11.5": 0.48,
    "73.12": 0.52,
    "73.13": 0.52,
    "73.14.0": 0.57,
    "73.14.1": 0.5,
    "73.14.2": 0.5,
    "73.14.3": 0.5,
    "73.14.4": 0.5,
    "73.15": 0.5,
    "73.16.0": 0.57,
    "73.16.1": 0.57,
    "73.16.2": 0.57,
    "73.17.0": 0.57,
    "73.17.1": 0.57,
    "73.17.2": 0.57,
    "73.17.3": 0.52,
    "73.17.4": 0.48,
    "73.17.5": 0.57,
    "73.17.6": 0.57,
    "74.0": 0.47,
    "74.1": 0.57,
    "74.2": 0.57,
    "74.3": 0.57,
    "74.4": 0.57,
    "74.5": 0.57,
    "74.6": 0.57,
    "75.0": 0.57,
    "75.1": 0.57,
    "75.2": 0.57,
    "76.0": 0.57,
    "76.1": 0.57,
    "76.2": 0.57,
    "76.3": 0.57,
    "_other": 0.57,
    "_default": 0.95,
    "Cabinet": 0.7,
    "Orangepot": 0.7,
    "Blackpot": 0.7,
    "Figure": 0.7,
    "Signage": 0.7
};
//////REMEMBER TO ADD CABINET IDS AND OTHER GROUP IDS TO THE THRESHOLDS ABOVE//////

const RLM_GROUP_CONFIG = {
    "55.0": "Cabinet",
    "56.0": "Cabinet",
    "57.0": "Cabinet",
    "58.0": "Cabinet",
    "60.0": "Cabinet",
    "61.0": "Cabinet",
    "65.0": "Cabinet",
    "66.0": "Cabinet",
    "67.0": "Cabinet",
    "68.0": "Cabinet",
    "69.0": "Cabinet",
    "70.0": "Cabinet",
    "71.0": "Cabinet",
    "72.0": "Cabinet",
    "73.0": "Cabinet",
    "73.10.0": "IvoryGroup1",
    "73.10.1": "IvoryGroup1",
    "73.10.2": "IvoryGroup1"
};

// Add specific thresholds for RLM groups
const RLM_GROUP_THRESHOLDS = {
    "Cabinet": 0.60,
    "IvoryGroup1": 0.75,
    "_default": 0.95
};

const MT_THRESHOLDS = {
    "1": 0.97,
    "2": 0.97,
    "3": 0.97,
    "4": 0.97,
    "5.0": 0.97,
    "5.1": 0.97,
    "5.2": 0.97,
    "5.3": 0.97,
    "5.4": 0.97,
    "6": 0.97,
    "7": 0.97,
    "8": 0.97,
    "9": 0.97,
    "10": 0.97,
    "11": 0.97,
    "12": 0.97,
    "13": 0.97,
    "14": 0.97,
    "15": 0.97,
    "16": 0.97,
    "17": 0.97,
    "18": 0.97,
    "19": 0.97,
    "20": 0.97,
    "21": 0.97,
    "22": 0.97,
    "23": 0.97,
    "24": 0.97,
    "25": 0.97,
    "26.0": 0.91,
    "26.1": 0.91,
    "26.2": 0.91,
    "26.3": 0.91,
    "26.4": 0.91,
    "_end": 0.97,
    "_other": 0.0,
    "_default": 0.97
};

const MT_GROUP_CONFIG = {}; // Empty object for MT - no grouping
const MT_GROUP_THRESHOLDS = {}; // Empty object for MT

const thresholdConfig = {
    RLM: RLM_THRESHOLDS,
    MT: MT_THRESHOLDS
}[process.env.REACT_APP_LOCATION];

// Add new groupConfig export based on location
export const groupConfig = {
    RLM: RLM_GROUP_CONFIG,
    MT: MT_GROUP_CONFIG
}[process.env.REACT_APP_LOCATION] || {};

export const groupThresholds = {
    RLM: RLM_GROUP_THRESHOLDS,
    MT: MT_GROUP_THRESHOLDS
}[process.env.REACT_APP_LOCATION] || {};

// Update your existing getThreshold function
export const getThreshold = (categoryId) => {
    // Check if it's a group threshold first (only for RLM)
    if (process.env.REACT_APP_LOCATION === 'RLM' && 
        Object.keys(groupThresholds).includes(categoryId)) {
        return groupThresholds[categoryId];
    }
    
    // Otherwise use regular threshold
    const threshold = thresholdConfig[categoryId];
    if (threshold === undefined) {
        console.log(`Warning: No threshold defined for category ${categoryId}, using default threshold (97%)`);
        return thresholdConfig._default;
    }
    return threshold;
};

// Keep your existing thresholdConfig export
export { thresholdConfig };