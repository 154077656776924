import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useContent } from '../hooks/useContent';
import './PopupStyles.css';

const AIInfoPopup = ({ onClose, userLanguage }) => {
  const content = useContent('aiInfo');

  const handleOverlayClick = (e) => {
    // Add console.log to debug
    console.log('Overlay clicked', e.target, e.currentTarget);
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="aiinfo-title">{content.title}</h2>
        <div className="popup-text">
          <p>{content.intro}</p>
          <p><strong>{content.features.title}</strong></p>
          <ul style={{ paddingLeft: '17px' }}>
            {content.features.list.map((feature, index) => (
              <li key={index}>
                <strong>{feature.title}</strong> {feature.text}
              </li>
            ))}
          </ul>
          <p><strong>{content.keepInMind.title}</strong> {content.keepInMind.text}</p>
          <p style={{ color: '#008626' }}>
            <strong>{content.feedback.title}</strong> {content.feedback.text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AIInfoPopup;