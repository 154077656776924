import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import RecordRTC from 'recordrtc';
import Lottie from 'react-lottie';
import listeningStopAnimation from '../assets/Listening-Stop.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faStop, faVolumeHigh, faArrowRotateLeft, faVolumeXmark, faArrowUp, faAnglesDown, faAnglesUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import ValidationPopup from './ValidationPopup';
import { useContent } from '../hooks/useContent';

const ObjectConversation = ({responseTitle, responseSummary, additionalInstructions, thumbnailImage, handleFindOutMoreClick, handleReturnToCameraClick, isFirstPage, responsePrompt, isAudioEnabled, setIsAudioEnabled,  userLanguage, userType, globalAudio, isRecording, setIsRecording, openInput, objectSubtitle, objectId, userId}) => {
  const content = useContent('conversation');
  
  //MOVING OBJECT SUMMARY SECTION///////////////////////////
  // const showObjectDetails = responseTitle && responseTitle !== "No object detected";  //DUPLICATE OF PARENT, CONSIDER COMBINING SOMETIME OR REMOVING REDUNDENT
    //const objectShortSummary = responseSummary
    // const [animateIcon, setAnimateIcon] = useState(false);
    
  //ORIGINAL OBJECT CONVERSATION SECTION///////////////////////////
  // const ipAddress = process.env.REACT_APP_IP_ADDRESS;
  // const ipAddressAWSTTS = process.env.REACT_APP_AWS_TTS_API_ENDPOINT;
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [showThinkingMessage, setShowThinkingMessage] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [audioUrls, setAudioUrls] = useState({}); // Object to hold audio URLs keyed by message IDs
  const [playingAudio, setPlayingAudio] = useState(null);
  const chatScrollRef = useRef(null);
  
  const [recordingTime, setRecordingTime] = useState("00:00");
  const [loadingMessageId, setLoadingMessageId] = useState(null);

  const [validationResults, setValidationResults] = useState({});
  const [showValidationModal, setShowValidationModal] = useState({ show: false, data: null });

  const [stream, setStream] = useState(null); 

  // Add new state for suggested questions
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const location = process.env.REACT_APP_LOCATION;

  const [showTellMeMore, setShowTellMeMore] = useState(false);
  
  // Function to fetch suggested questions
  const fetchSuggestedQuestions = async () => {
    try {
      const response = await fetch("https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/suggested-questions", {  // Replace with your actual API endpoint
        // const response = await fetch(process.env.REACT_APP_SUGGESTED_QUESTIONS_ENDPOINT, {  //REACT_APP_SUGGESTED_QUESTIONS_ENDPOINT
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          conversation_history: messages,
          Object_ID: objectId,
          location: location,
          userId: userId,
          user_language: userLanguage
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Suggested Questions API Response:", data);
      setSuggestedQuestions(data.suggested_questions || []);
    } catch (error) {
      console.error("Error fetching suggested questions:", error);
      setSuggestedQuestions([]);
    }
  };

  // Add useEffect to fetch suggested questions after each AI response
  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1].role === 'assistant') {
      fetchSuggestedQuestions();
    }
  }, [messages]);

  // Add useEffect to clear suggested questions when returning to camera view
  useEffect(() => {
    if (isFirstPage) {
      setSuggestedQuestions([]);
    }
  }, [isFirstPage]);

  // const prevResponsePromptRef = useRef();  //TEMPORARY UNTIL MORE PERMANENT SOLUTION TO REMEMBER IF ALREADY RUN THIS OBJECT
  const defaultOptionsListening = {
    loop: true,
    autoplay: true,
    animationData: listeningStopAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  //ORIGINAL OBJECT CONVERSATION SECTION///////////////////////////
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // const prevResponsePrompt = prevResponsePromptRef.current;   //TEMPORARY UNTIL MORE PERMANENT SOLUTION TO REMEMBER IF ALREADY RUN THIS OBJECT
    // prevResponsePromptRef.current = responsePrompt;
    setMessages([""]);
  
    if (!isFirstPage) {  // && responsePrompt !== prevResponsePrompt
      // console.log("Effect running with responsePrompt:", responsePrompt);
      const getInitialBotResponse = async () => {
        
        const botData = await sendPromptToBackend(responsePrompt, []);
        if (botData && botData.response) {
          const newId = uuidv4();
          setMessages([{ id: newId, role: "assistant", content: botData.response }]);
          fetchAndPlayAudio(botData.response, newId, botData.summary_type);
          fetchAndValidateResponse(botData.response, newId);
        }
      };
      getInitialBotResponse();
    }
  
    return () => {
      // Cleanup function
      Object.values(audioUrls).forEach(url => URL.revokeObjectURL(url));
    };
  }, [responsePrompt, isFirstPage]); // Dependencies array

  const sendPromptToBackend = async (responsePrompt, conversationHistory, isSuggestedQuestion = false, isTellMeMore = false) => {
    setShowThinkingMessage(true);

    // Filter out messages with the role "error"
    const filteredHistory = conversationHistory.filter(msg => msg.role !== "error");
  
    try {
      const requestBody = { 
        prompt_data: responsePrompt,
        conversation_history: filteredHistory,
        user_language: userLanguage,
        user_type: userType,
        open_input: openInput,
        additional_instructions: additionalInstructions,
        Object_ID: objectId,
        location: location,
        userId: userId,
        is_suggested_question: isSuggestedQuestion,
        is_tell_me_more: isTellMeMore
      };
      
      // console.log("Sending request to backend:", requestBody);

      const response = await fetch(process.env.REACT_APP_TEXT_GENERATION_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      setShowThinkingMessage(false);
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Backend Response:", data);
      console.log("Summary Type:", data.summary_type);
      // if (data.response && data.role === "assistant") {   THIS BLOCK SEEMED TO BE TRIGGERING TWO RESPONSES
      //   fetchAndPlayAudio(data.response);
      // }
      return { response: data.response, summary_type: data.summary_type };
    } catch (error) {
      console.error("Error sending prompt to backend:", error);
      // Add an error message to the conversation history
      setMessages(currentMessages => [
        ...currentMessages,
        { 
          id: uuidv4(), 
          role: "assistant", 
          content: content.messages.error || "Oops! I seem to have had a little brain freeze there. Can you try asking me again?"
        }
      ]);
    }
  };
  
  useLayoutEffect(() => {  //USEEFFECT ALSO WORKS. THIS IS TO REPLACE THE below useeffect as it actually scrolled up to the top component. useLayoutEffect fires synchronously after all DOM mutations, ensuring that the DOM is updated before you try to scroll.
    const chatContainer = chatScrollRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [isRecording, messages, showThinkingMessage, isTranscribing, suggestedQuestions, showTellMeMore]);
  

  const fetchAndPlayAudio = async (text, messageId, summary_type) => {
      setLoadingMessageId(messageId); 
      if (!isAudioEnabled) return;
      
      // Stop any currently playing audio
      stopAudio();

      if (audioUrls[messageId]) {
          // Audio URL already exists, play audio
          if (globalAudio) {
              globalAudio.src = audioUrls[messageId];
              globalAudio.play().catch(error => {
                  // Handle errors for autoplay restrictions
                  console.error('Playback failed:', error);
              });
              setPlayingAudio(globalAudio);  //DECIDE IF I STILL NEED THIS, I THINK I DO FOR OTHER AUDIO LOGIC???
          } else {
              console.error('Global audio element is not initialized.');
          }
      } else {
          // Fetch new audio and set URL
          try {
              console.log("Fetching audio from backend for message:", messageId);

              if (summary_type) {  // If we have a static summary type
                  try {
                      console.log("Attempting to fetch static audio for:", summary_type);
                      // Determine which bucket to use based on location
                      const bucketName = location === 'RLM' ? 'rlm-static-ts-audio-v1' : 'mt-audio-v1';
                      const s3Response = await fetch(`https://${bucketName}.s3.eu-west-2.amazonaws.com/${objectId}/${summary_type}.mp3`);
                      if (s3Response.ok) {
                          const audioBlob = await s3Response.blob();
                          console.log("Audio blob received:", {
                              size: audioBlob.size,
                              type: audioBlob.type
                          });
              
                          // Create a new audio element specifically for this playback - FOR SOME REASON IT DIDNT LIKE REUSING THE GLOBAL AUDIO PLAYBACK i think
                          const tempAudio = new Audio();
                          tempAudio.preload = 'auto';
                          tempAudio.playsinline = true;
                          
                          // Set up event listeners before setting source
                          tempAudio.addEventListener('canplaythrough', () => {
                              console.log("Audio can play through");
                          });
              
                          tempAudio.addEventListener('error', (e) => {
                              console.error("Audio error:", e);
                          });
              
                          // Create blob URL and set source
                          const audioUrl = URL.createObjectURL(new Blob([audioBlob], { type: 'audio/mpeg' }));
                          tempAudio.src = audioUrl;
              
                          try {
                              await tempAudio.play();
                              console.log("Static audio playback started successfully");
                              setAudioUrls(prevAudioUrls => ({ ...prevAudioUrls, [messageId]: audioUrl }));
                              setPlayingAudio(tempAudio);
                              setLoadingMessageId(null);
                              
                              // Clean up when audio ends
                              tempAudio.addEventListener('ended', () => {
                                  URL.revokeObjectURL(audioUrl);
                                  setPlayingAudio(null);
                              });
                              
                              return true;
                          } catch (playError) {
                              console.error("Playback failed:", playError);
                              URL.revokeObjectURL(audioUrl);
                              throw playError;
                          }
                      }
                  } catch (error) {
                      console.error("Static audio error:", error);
                      console.log("Falling back to TTS API");
                  }
              }

              //// ELEVENLABS VERSION
              // const response = await fetch(`https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/tts-el`, { //ElevenLabs Version //BREAK HERE IF WANT TO STOP AUDIO GENERATION
              //     method: 'POST',
              //     headers: {
              //         'Content-Type': 'application/json',
              //         'x-api-key': 'wgG4g0zey21oTMZCX5ZNQ8ydEB2Pmq57th4Q1tre'
              //     },
              //     body: JSON.stringify({ 
              //         text: text,
              //         user_language: userLanguage, 
              //         user_type: userType,
              //         location: location,
              //         userId: userId
              //     }),
              // });

              //// POLLY VERSION
              // const response = await fetch(`https://kjgw2i5dhh.execute-api.eu-west-2.amazonaws.com/TextToSpeechFunction_Prototype`, { //POLLY VERSION //BREAK HERE IF WANT TO STOP AUDIO GENERATION //with environment: ${ipAddressAWSTTS}
              //     method: 'POST',
              //     headers: {
              //         'Content-Type': 'application/json',
              //     },
              //     body: JSON.stringify({ 
              //         text: text,
              //         user_language: userLanguage, 
              //         user_type: userType,
              //         location: location,
              //         userId: userId
              //     }),
              // });

              //// POLLY VERSION
              const apiEndpoint = (process.env.REACT_APP_LOCATION === 'NEITHER' || userLanguage === 'Welsh')  //PUT BACK TO RLM IF WANT POLLY 
                  ? 'https://kjgw2i5dhh.execute-api.eu-west-2.amazonaws.com/TextToSpeechFunction_Prototype'
                  : 'https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/tts-el';
              const headers = (process.env.REACT_APP_LOCATION === 'NEITHER' || userLanguage === 'Welsh') //PUT BACK TO RLM IF WANT POLLY 
                  ? { 'Content-Type': 'application/json' }
                  : {
                      'Content-Type': 'application/json',
                      'x-api-key': 'wgG4g0zey21oTMZCX5ZNQ8ydEB2Pmq57th4Q1tre'
                    };
              const response = await fetch(apiEndpoint, {
                  method: 'POST',
                  headers: headers,
                  body: JSON.stringify({ 
                      text: text,
                      user_language: userLanguage, 
                      user_type: userType,
                      location: location,
                      userId: userId
                  }),
              });

              //// POLLY VERSION
              // const response = await fetch(`https://kjgw2i5dhh.execute-api.eu-west-2.amazonaws.com/TextToSpeechFunction_Prototype`, { //POLLY VERSION //BREAK HERE IF WANT TO STOP AUDIO GENERATION //with environment: ${ipAddressAWSTTS}
              //     method: 'POST',
              //     headers: {
              //         'Content-Type': 'application/json',
              //     },
              //     body: JSON.stringify({ 
              //         text: text,
              //         user_language: userLanguage, 
              //         user_type: userType,
              //         location: location,
              //         userId: userId
              //     }),
              // });

              console.log("Audio fetch response:", response);
              if (!response.ok) {
                throw new Error(`Audio fetch failed with status: ${response.status}`);
              }

              let audioUrl; // Declare audioUrl here

              if (process.env.REACT_APP_LOCATION === 'NEITHER' || userLanguage === 'Welsh') {    //PUT BACK TO RLM IF WANT POLLY 
                  // Handle Polly response (RLM)
                  const audioBlob = await response.blob();
                  audioUrl = URL.createObjectURL(audioBlob);
              } else {
                  // Handle ElevenLabs response (MT)
                  const base64Audio = await response.text();
                  const binary = atob(base64Audio.replace(/\s/g, ''));
                  const len = binary.length;
                  const bytes = new Uint8Array(len);
                  for (let i = 0; i < len; i++) {
                      bytes[i] = binary.charCodeAt(i);
                  }
                  const audioBlob = new Blob([bytes], { type: 'audio/mpeg' });
                  audioUrl = URL.createObjectURL(audioBlob);
              }
              
              //THE BELOW CODE IS NEEDED FOR POLLY, COMMENT OUT FOR ELEVENLABS//
              // const audioBlob = await response.blob(); //FOR UNKNOWN REASON WE DONT NEED TO DECODE THE BASE64 AUDIO RESPONSE FROM THE LAMBDA FUNCTION
              // // console.log("Audio Blob:", audioBlob);
              // const audioUrl = URL.createObjectURL(audioBlob);
              // // console.log("Audio URL:", audioUrl);
              // // downloadAudioBlob(audioUrl);
              // //Update audioUrls state
              // setAudioUrls(prevAudioUrls => ({ ...prevAudioUrls, [messageId]: audioUrl }));
              //THE ABOVE CODE IS NEEDED FOR POLLY, COMMENT OUT FOR ELEVENLABS//

              //THE BELOW CODE IS NEEDED FOR ELEVENLABS, COMMENT OUT FOR POLLY//
              // // The response is a base64 string, so we just read it as text
              // const base64Audio = await response.text(); 
              // // console.log("Base64 audio:", base64Audio);
              // // Convert the base64 string into binary and then to Blob
              // const binary = atob(base64Audio.replace(/\s/g, '')); // Decode base64
              // const len = binary.length;
              // const bytes = new Uint8Array(len);
              // for (let i = 0; i < len; i++) {
              //     bytes[i] = binary.charCodeAt(i);
              // }
              // const audioBlob = new Blob([bytes], { type: 'audio/mpeg' });
              // // Create a URL for the Blob and update the state
              // const audioUrl = URL.createObjectURL(audioBlob);
              // // console.log("Audio URL:", audioUrl);
              // setAudioUrls(prevAudioUrls => ({ ...prevAudioUrls, [messageId]: audioUrl }));
              //THE ABOVE CODE IS NEEDED FOR ELEVENLABS, COMMENT OUT FOR POLLY//
              
              if (globalAudio) {
                  globalAudio.src = audioUrl;
                  // globalAudio.type = 'audio/mpeg';
                  globalAudio.play()
                    .then(() => {
                        console.log("Audio playback started successfully.");
                    })
                    .catch(error => {
                        console.error("Playback failed:", error);
                    });
                  setPlayingAudio(globalAudio);
              } else {
                  console.error('Global audio element is not initialized.');
              }
              
          } catch (error) {
              console.error("Error fetching audio:", error);
          }
      }
      setLoadingMessageId(null);
  };

  const fetchAndValidateResponse = async (textToValidate, assistantMessageId) => {
    if (userType === 'child' || userLanguage === 'Welsh') {
      console.log("Skipping validation for child user type.");
      return; // Early return to skip validation
    }
    
    try {
      const validationResponse = await fetch(`https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/validation`, {    //LOCAL VERSION: `${ipAddress}/validate`
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          content: textToValidate,  
          prompt_data: responsePrompt,
          location: location,
          userId: userId,  // Add this
          user_language: userLanguage
        }),
      });
  
      if (!validationResponse.ok) {
        throw new Error(`HTTP error! status: ${validationResponse.status}`);
      }
  
      const validationData = await validationResponse.json();
      console.log("Validation Response:", validationData);
      
     
      setValidationResults(prevResults => {
        const newResults = { ...prevResults, [assistantMessageId]: validationData };
        console.log("Updated Validation Results:", newResults);  // Debug the updated state
        return newResults;
      });
    } catch (error) {
      console.error("Error fetching validation:", error);
    }
  };

  const stopAudio = () => {
    if (playingAudio) {
      playingAudio.pause();
      playingAudio.currentTime = 0;
      setPlayingAudio(null);
    }
  };

  const toggleAudio = () => {
    if (isAudioEnabled && playingAudio) {
      stopAudio();
    }
    setIsAudioEnabled(!isAudioEnabled);
  };

  const sendMessage = async (text = userInput, isSuggestedQuestion = false, isTellMeMore = false) => {
    stopAudio();
    if (!text.trim()) return;
  
    const userMessage = { id: uuidv4(), role: "user", content: text };
    
    setMessages(currentMessages => [...currentMessages, userMessage]);
    setUserInput('');
    setIsSending(true);
    setSuggestedQuestions([]); // Clear suggested questions
  
    const updatedMessages = [...messages, userMessage];
    const botResponse = await sendPromptToBackend(
      responsePrompt, 
      updatedMessages, 
      isSuggestedQuestion,
      isTellMeMore  // Pass the new parameter to backend
    );
    
    if (botResponse && botResponse.response) {
      const newId = uuidv4();
      setMessages(currentMessages => [...currentMessages, { 
        id: newId, 
        role: "assistant", 
        content: botResponse.response 
      }]);
      fetchAndPlayAudio(botResponse.response, newId);
      fetchAndValidateResponse(botResponse.response, newId);
    }
    setIsSending(false);
  };

  // Add handleSuggestedQuestionClick function
  const handleSuggestedQuestionClick = (question) => {
    sendMessage(question, true);  // Pass true to indicate this is a suggested question
  };

  const startRecording = async () => {
    try {
      console.log("Starting recording...");

      stopAudio();

      console.log("Requesting microphone access with getUserMedia...");

      const constraints = {
        audio: {
          echoCancellation: { ideal: true },
          noiseSuppression: { ideal: true },
          autoGainControl: { ideal: true },
          sampleRate: { ideal: 44100 }
        }
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      // For iOS Safari, we need to ensure audio context is created after user interaction
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      if (audioContext.state === 'suspended') {
        await audioContext.resume();
      }

      console.log("Microphone access granted. Stream created:", stream);
      // console.log("Stream tracks before storing in state:", stream.getAudioTracks());
      console.log("Stream ID:", stream.id);
      console.log("Active:", stream.active);
      console.log("Stream tracks:", stream.getAudioTracks());

      stream.getAudioTracks().forEach((track, index) => {
        console.log(`Track ${index} ID:`, track.id);
        console.log(`Track ${index} Kind:`, track.kind);
        console.log(`Track ${index} Label:`, track.label);
        console.log(`Track ${index} Muted:`, track.muted);
        console.log(`Track ${index} Enabled:`, track.enabled);
        console.log(`Track ${index} ReadyState:`, track.readyState);
      });
      
      setStream(stream); // Store the stream in state

      const newRecorder = new RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/webm;codecs=pcm', // More compatible format
        recorderType: RecordRTC.StereoAudioRecorder,
        numberOfAudioChannels: 1,
        desiredSampRate: 44100,
        bufferSize: 4096
      });
      
      newRecorder.startRecording();
      setRecorder(newRecorder);
      setIsRecording(true);

      // Remove all HARK initialization and event listeners

      console.log("Recording setup complete.");
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  // Add this useEffect for iOS Safari audio initialization
  // useEffect(() => {
  //   const initAudio = async () => {
  //     try {
  //       const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  //       if (audioContext.state === 'suspended') {
  //         await audioContext.resume();
  //       }
  //     } catch (error) {
  //       console.error('Error initializing audio context:', error);
  //     }
  //   };
  //   // Initialize on first user interaction
  //   const handleFirstInteraction = () => {
  //     initAudio();
  //     document.removeEventListener('touchstart', handleFirstInteraction);
  //   };
  //   document.addEventListener('touchstart', handleFirstInteraction);
  //   return () => {
  //     document.removeEventListener('touchstart', handleFirstInteraction);
  //   };
  // }, []);

  // Function to stop recording and send audio for processing
  const stopRecording = async () => {
    console.log("Stopping recording...");
    recorder.stopRecording(async () => {
      const audioBlob = recorder.getBlob();
      setIsRecording(false);

      if (stream) {
        console.log("Stopping all tracks in the stream...");
        stream.getTracks().forEach(track => {
          console.log(`Stopping track with kind: ${track.kind}`);
          track.stop();
        });
        setStream(null);  // Clear the stream from state
      } else {
        console.log("No active stream found to stop.");
      }
      
      // Remove HARK cleanup code
      
      // Always send the audio now that we're not checking for voice detection
      console.log("Sending audio...");
      await sendAudioToBackend(audioBlob);

      console.log("Recording and cleanup complete.");
    });
  };

  const cancelRecording = (e) => {
    e.stopPropagation();
    console.log("Cancelling recording...");
    
    if (recorder) {
      console.log("Stopping recorder...");
      recorder.stopRecording(() => {
        recorder.destroy();
        setRecorder(null);
        console.log("Recorder destroyed.");
      });
    }

    if (stream) {
      console.log("Stopping all tracks in the stream...");
      stream.getTracks().forEach(track => {
        console.log(`Stopping track with kind: ${track.kind}`);
        track.stop();
      });
      setStream(null);
    } else {
      console.log("No active stream found to stop.");
    }

    // Remove HARK cleanup code

    setIsRecording(false);
    setIsTranscribing(false);
    console.log("Cancellation complete.");
  };





  

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (!document.hidden) {
  //       console.log("Tab became visible, restarting microphone...");
  //       cancelRecording();  // Stop any existing (potentially disrupted) stream
  //       startRecording();   // Start a fresh recording
  //     }
  //   };
  
  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);

  // window.addEventListener('blur', () => {
  //   console.log("Tab is blurred, stopping microphone...");
  //   cancelRecording();  // Stop microphone when tab loses focus
  // });
  

  const sendAudioToBackend = async (audioBlob) => {
    setIsTranscribing(true);
    setIsSending(true);

    //UNCOMMENT THE BELOW IF WANT TO CONVERT TO BASE64 AND USE AWS, BASE 64 NOT QUITE AS EFFICIENT AS BLOB//
    const reader = new FileReader();
    reader.readAsDataURL(audioBlob);
    reader.onloadend = async () => {
        const base64Audio = reader.result.split(',')[1]; // Remove the data URL prefix

        const payload = {
            body: base64Audio,
            isBase64Encoded: true,
            location: location,
            userId: userId
        };

        try {
            const response = await fetch(`https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/speech-to-text`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            console.log("Response from backend:", response);  // Log the raw response

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Parsed response data:", data);  // Log the parsed response data
            
            // Now parse the body content as JSON
            const parsedBody = JSON.parse(data.body);
            console.log("Transcribed text:", parsedBody.text);  // Log the transcribed text
            
            // Check for error in response
            if (parsedBody.error) {
                throw new Error(parsedBody.error);
            }

            setIsTranscribing(false);
            setMessages(currentMessages => [
                ...currentMessages,
                { id: uuidv4(), role: "user", content: parsedBody.text }
            ]);

            setSuggestedQuestions([]);

            const botResponse = await sendPromptToBackend(responsePrompt, [
                ...messages,
                { id: uuidv4(), role: "user", content: parsedBody.text }
            ]);

            if (botResponse && botResponse.response) {
                const newId = uuidv4();
                setMessages(currentMessages => [...currentMessages, { id: newId, role: "assistant", content: botResponse.response }]);
                fetchAndPlayAudio(botResponse.response, newId);
                fetchAndValidateResponse(botResponse.response, newId);
            }
        } catch (error) {
            console.error("Error sending audio to backend:", error);
            setMessages(currentMessages => [
                ...currentMessages,
                { 
                    id: uuidv4(), 
                    role: "error", 
                    content: "Sorry, there was difficulty in hearing your question, please try asking again."
                }
            ]);
        } finally {
            setIsTranscribing(false);
            setIsSending(false);
        }
    };
  };
  //UNCOMMENT THE ABOVE IF WANT TO CONVERT TO BASE64 AND USE AWS, BASE 64 NOT QUITE AS EFFICIENT AS BLOB//
    
    
  //UNCOMMENT ALL OF THE BELOW IF WANT TO RUN LOCAL VERSION//
  //   const formData = new FormData();
  //   formData.append("file", audioBlob, "recording.webm");
  
  //   try {
  //     const response = await fetch(`https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/speech-blob-to-text`, {  //LOCAL VERSION: ${ipAddress}/audio-to-text
  //       method: 'POST',
  //       body: formData,
  //     });
  
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }
  
  //     const data = await response.json();
  //     setIsTranscribing(false);
  //     setMessages(currentMessages => [
  //       ...currentMessages,
  //       { id: uuidv4(), role: "user", content: data.text }
  //     ]);
      
  //     if (data.text) {
  //       const botResponse = await sendPromptToBackend(responsePrompt, [
  //         ...messages,
  //         { id: uuidv4(), role: "user", content: data.text }
  //       ]);
  
  //       if (botResponse) {
  //         const newId = uuidv4();
  //         setMessages(currentMessages => [...currentMessages, { id: newId, role: "assistant", content: botResponse }]);
  //         fetchAndPlayAudio(botResponse, newId);
  //         fetchAndValidateResponse(botResponse, newId);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error sending audio to backend:", error);
  //   } finally {
  //     setIsSending(false);
  //   }
  // };
  //UNCOMMENT ALL OF THE ABOVE IF WANT TO RUN LOCAL VERSION//

  useEffect(() => {
    let interval;
    if (isRecording) {
      const startTime = Date.now();
      interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const minutes = Math.floor(elapsedTime / 60000).toString().padStart(2, '0');
        const seconds = Math.floor((elapsedTime % 60000) / 1000).toString().padStart(2, '0');
        setRecordingTime(`${minutes}:${seconds}`);
      }, 1000);
    } else {
      setRecordingTime("00:00");
    }
    return () => clearInterval(interval);
  }, [isRecording]);
  
  // Add this helper function at the component level
  const getValidationButtonProps = (validationTag) => {
      switch(validationTag) {
        case "Yes":
          return {
            className: "validated",
            text: content.messages.validation.showSources,
            show: true
          };
        case "No":
          return {
            className: "not-validated",
            text: content.messages.validation.notValidated,
            show: true
          };
        case "NA":
        default:
          return {
            show: false
          };
      }
  };

  
  // Add this effect to manage the button visibility
  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      setShowTellMeMore(lastMessage.role === 'assistant' && !isSending && !isTranscribing);
    }
  }, [messages, isSending, isTranscribing]);

  // Add this function to handle the "Tell me more" click
  const handleTellMeMore = () => {
    sendMessage(content.messages.tellMeMore, false, true);
    setShowTellMeMore(false);
  };

  return (
    <>
            
      {!isFirstPage && (
        <div className="object-summary-navigation-up">
          <div className="experimental-disclaimer">
            {content.messages.experimentalDisclaimer}
          </div>
          <button className="return-camera-button" onClick={handleReturnToCameraClick}>
            {/* <FontAwesomeIcon icon={faAnglesUp} style={{ color: "#000000" }} /> */}
            <img src="/camera4-black.png" alt="Camera" className="camera-icon" />
          </button>
          <div className="return-camera-text" onClick={handleReturnToCameraClick}>
            {content.navigation.returnToCamera}
          </div>
        </div>
      )}  

      {/* {
        isFirstPage ? (
          <div className="white-box">
          </div>
        ) : (
          <div className="object-summary-navigation-up">
            <button className="return-camera-button" onClick={handleReturnToCameraClick}>
              <FontAwesomeIcon icon={faAnglesUp} style={{ color: "#000000" }} />
            </button>
            <div className="return-camera-text" onClick={handleReturnToCameraClick}>Return to camera</div>
          </div>
        )
      } */}
      
      <div className="fade-effect">
      <div className="gradient-top"></div>
      <div className="conversation-scroll" ref={chatScrollRef}>
        <div className={`object-summary-info ${!isFirstPage ? 'second-page' : ''}`}>
          <div className="summary-text-container">
            <h2>{responseTitle}</h2>
            {objectSubtitle && <h3 className="object-subtitle">{objectSubtitle}</h3>}
            <p>{responseSummary}</p>
          </div>
          {thumbnailImage && (
            <div className="thumbnail-image-container" onClick={handleFindOutMoreClick}>
              <img src={thumbnailImage} alt="Object Thumbnail" />
            </div>
          )}                
        </div>
        {/* Add the separator here */}
        <div className="section-separator"></div>
        {isFirstPage && (
          <div className="object-summary-navigation-down">
            <div className="more-info-text" onClick={handleFindOutMoreClick}>
              {content.navigation.moreInfo}
            </div>
            <button className="more-info-button" onClick={handleFindOutMoreClick}>
              <FontAwesomeIcon icon={faAnglesDown} style={{ color: "#000000" }} />
            </button>
          </div>
        )}
          
        
        
        <div className="chat-container" >
          {messages.map((message, index) => (  // Added index here to ensure key is unique
            message.content && (
              <div key={index} className={`message ${message.role} ${location === 'RLM' ? 'rlm' : 'mt'}`}>
                {message.role === 'error' ? (
                  <div className="message-text error">{message.content}</div>
                ) : (
                  <>
                    <div className="message-photo"></div>
                    <div className="message-sender">
                      {message.role === 'user' ? content.sender.user : content.sender.assistant}
                      {message.role === 'assistant' && (
                        <>
                          <button 
                            onClick={toggleAudio} 
                            className="audio-toggle-button">
                            {isAudioEnabled ? (
                              <div className={message.id === loadingMessageId ? "loading-container loading" : "loading-container"}>
                                  <FontAwesomeIcon icon={faVolumeHigh} style={{ color: "#000000" }} className="speaker-icon" />
                              </div>  
                            ) : (
                              <div className="adjust-size-container"> 
                                <FontAwesomeIcon icon={faVolumeXmark} style={{ color: "#df4e4e" }}  />
                              </div>
                            )}
                          </button>
                          {isAudioEnabled && (
                            <button 
                              onClick={() => fetchAndPlayAudio(message.content, message.id)} 
                              className="replay-button">
                              <div className="adjust-size-container">
                                <FontAwesomeIcon icon={faArrowRotateLeft} style={{ color: "#000000" }} />
                              </div>
                            </button>
                          )}
                        </>
                      )}
                      {message.role === 'assistant' && validationResults[message.id] && (
                        <div className="validation-button">
                          {(() => {
                            const buttonProps = getValidationButtonProps(validationResults[message.id].validation_tag);
                            if (!buttonProps.show) return null;
                            
                            return buttonProps.className === "not-validated" ? (
                              <span className={buttonProps.className}>{buttonProps.text}</span>
                            ) : (
                              <button 
                                className={buttonProps.className} 
                                onClick={() => setShowValidationModal({ show: true, data: validationResults[message.id] })}
                              >
                                {buttonProps.text}
                              </button>
                            );
                          })()}
                        </div>
                      )}
                    </div>
                    <div className="message-text">{message.content}</div>
                  </>
                )}
              </div>
            )
          ))}
          {showThinkingMessage && (
            <div className={`message assistant ${location === 'RLM' ? 'rlm' : 'mt'}`}>
              <div className="message-photo"></div>
              <div className="message-sender">{content.sender.assistant}</div>
              <div className="message-text thinking">{content.messages.thinking}</div>
            </div>
          )}
          {isTranscribing && (
            <div className={`message user ${location === 'RLM' ? 'rlm' : 'mt'}`}>
              <div className="message-photo"></div>
              <div className="message-sender">{content.sender.user}</div>
              <div className="message-text transcribing">{content.messages.transcribing}</div>
            </div>
          )}
          {showTellMeMore && (
            <div className="tell-me-more-container">
              <button 
                className="tell-me-more-button"
                onClick={handleTellMeMore}
                disabled={isSending || isTranscribing}
              >
                <FontAwesomeIcon icon={faPlus} className="plus-icon" />
                {content.messages.tellMeMore}
              </button>
            </div>
          )}
          <br/>
        </div>

        {showValidationModal.show && (
          <ValidationPopup 
            onClose={() => setShowValidationModal({ show: false, data: null })}
            validationData={showValidationModal.data}
            userLanguage={userLanguage}
          />
        )}

      </div>
      <div className="gradient-bottom"></div>  
      </div>

      {/* Replace the conditional suggested questions container with an always-present one */}
      <div className="suggested-questions-container">
        {suggestedQuestions.map((question, index) => (
          <button
            key={index}
            className="suggested-question-button"
            onClick={() => handleSuggestedQuestionClick(question)}
            disabled={isSending || isTranscribing}
          >
            {question}
          </button>
        ))}
      </div>

      <div className="chat-input-area">
        <div>
          <input
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder={content.messages.inputPlaceholder}
            onKeyDown={(e) => e.key === 'Enter' && !isSending && userInput.trim() && sendMessage()}
            disabled={isSending}
          />
          <button 
            className={`mic-button ${!userInput.trim() ? 'disabled' : ''}`}
            onClick={() => userInput.trim() && sendMessage()}
            disabled={!userInput.trim() || isSending}
          >
            <FontAwesomeIcon icon={faArrowUp} style={{ color: "#ffffff" }} />
          </button>
        </div>
        <p className="disclaimer-text">{content.messages.disclaimer}</p>
      </div>
      {isRecording && (
        <div className="stop-recording-box" onClick={stopRecording} >
          <div className="timer">{recordingTime}</div>
          <div className="listening-animation-container">
            <Lottie options={defaultOptionsListening} />
          </div>
          <p className="stop-recording-text">{content.messages.stopRecording}</p>
          <button className="cancel-recording-button" onClick={cancelRecording}>
            {content.messages.cancelRecording}
          </button>
        </div>
      )}
      
  
    </>
  );
};
export default ObjectConversation;








