import React, { useState } from 'react';

//THE BELOW IS WHAT IVE COMMENTED OUT FOR THE TRIALS
// import { useNavigate } from 'react-router-dom';  //USE THIS WHEN NEED TO PROGRAMMATIC NAVIGATE (does it still avoid new requests to server?)
// import personImage from '../assets/Person.png';
// import { faMap } from '@fortawesome/free-solid-svg-icons';

// import { Link } from 'react-router-dom';  //USE THIS IF DOING DIRECT LINK

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import AIInfoPopup from './AIInfoPopup';
import SurveyPopup from './SurveyPopup';

const NavFromObject = ({ userId, userLanguage, setUserLanguage, isFirstPage }) => {

    //THE BELOW IS WHAT IVE COMMENTED OUT FOR THE TRIALS
    // const navigate = useNavigate();

    // const Nav2Personalisation = () => {
    //     navigate('/personalisation'); // Navigate to the Personalisation page
    //   };
    
    const [showAIInfo, setShowAIInfo] = useState(false);
    const [showSurvey, setShowSurvey] = useState(false);
    const [showLanguagePopup, setShowLanguagePopup] = useState(false);
    const location = process.env.REACT_APP_LOCATION;
  
    const handleSurveyContinue = () => {
      window.open('YOUR_SURVEY_URL', '_blank');
      setShowSurvey(false);
    };  

    const handleLanguageToggle = () => {
        const newLanguage = userLanguage === 'Welsh' ? 'English' : 'Welsh';
        setUserLanguage(newLanguage);
        localStorage.setItem('userLanguage', newLanguage);
        
        setShowLanguagePopup(true);
        setTimeout(() => setShowLanguagePopup(false), 3000);
    };

    return (
        <>
            <button onClick={() => setShowAIInfo(true)} className="nav2info-button">
                <FontAwesomeIcon icon={faInfo} className="info-icon" style={{color: "#ffffff"}} />
            </button>
            
            {/* Only show language button for RLM AND when in camera view */}
            {location === 'RLM' && isFirstPage && (
                <button onClick={handleLanguageToggle} className="nav2language-button">
                    <img src="/welsh-english-flag.png" alt="Toggle Language" className="language-icon" />
                </button>
            )}

            <button onClick={() => setShowSurvey(true)} className="nav2survey-button">
                <FontAwesomeIcon icon={faClipboardList} className="survey-icon" style={{color: "#ffffff"}} />
            </button>

            {showAIInfo && (
                <AIInfoPopup 
                    onClose={() => setShowAIInfo(false)} 
                    userLanguage={userLanguage}
                />
            )}
            {showSurvey && (
                <SurveyPopup 
                onClose={() => setShowSurvey(false)}
                userId={userId}
                location={location}
                userLanguage={userLanguage}
                />
            )}
            {showLanguagePopup && (
                <div className="language-change-popup">
                    <p>Bydd yr iaith yn newid pan fyddwch chi'n sganio'r gwrthrych nesaf</p>
                    <p>The language will switch when you scan the next object</p>
                </div>
            )}
            
            {/* <button onClick={Nav2Personalisation} className="nav2personalisation-button">
                <img src={personImage} alt="Person" className="person-nav-icon" />
            </button>
        
            <button className="nav2map-button">
                <FontAwesomeIcon icon={faMap} className="map-icon" style={{color: "#ffffff",}} />
            </button> */}
        </>
    );
};

export default NavFromObject;
